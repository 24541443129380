import {Button as RNEUIButton, ButtonProps} from '@rneui/themed';
import React from 'react';

const Button = (props: ButtonProps) => {
  return <RNEUIButton
    {...props}
  />;
};

export default Button;
