import {NavigationContainerRef} from '@react-navigation/native';
import {SourceKey} from 'paper-fetch';
import React from 'react';

import Collection, {createNewCollection} from './common/collection';
import Paper, {PartialPaper} from './common/paper';
import {SortType} from './common/store';
import Tag from './common/tag';
import {ModalOptionsType} from './components/Modal';
import {MainStackParamList} from './Main';
import {AppStackParamList} from './Navigation';

export enum ListDisplayType {
  Regular = 'regular',
  CollectionSearch = 'collection_search',
  LibrarySearch = 'library_search',
  WebSearch = 'web_search',
}

/* eslint sort-keys: ["error"] */
export type AppContextType = {
  newCollection: (title: string) => Promise<Collection>;
  savePublicCollection: (c: Collection) => Promise<void>;
  addPaperToLibrary: (p: PartialPaper | Paper) => Promise<void>;
  addPaperToCollection: (p: Paper, c: Collection) => Promise<void>;
  removePaperFromLibrary: (pid: string | string[]) => Promise<void>;
  removePaperFromCollection: (pid: string, c: Collection) => Promise<void>;
  fetchPaper: (
    p: Paper,
    source?: SourceKey[],
    forceRefresh?: boolean,
    updateProgressFn?: (p: Paper, msg: string) => void,
    updatePaperFn?: (p: Paper) => void,
  ) => Promise<Paper>;
  resetTags: () => Promise<Record<string, Tag>>;
  changeCurrentPaper: (_p: PartialPaper) => void;
  clearTags: () => Promise<void>;
  clearLocalData: () => Promise<void>;
  deleteCollection: (c: Collection) => Promise<void>;
  savePaperAndSync: (p: Paper, sync?: boolean) => Promise<Paper>;
  saveCollectionAndSync: (c: Collection) => Promise<Collection>;
  download: (p: Paper,
    onProgress?: (received: number, total: number) => void) => Promise<void>;
  removePdf: (pid: string) => Promise<void>;
  share: () => Promise<void>;
  sharePdf: (p: Paper) => Promise<void>;
  loadAppData: () => Promise<void>;
  isDarkMode: () => boolean;
  onPaperOrderChanged: (sortType: SortType) => void;
  isPaperInLibrary: (pid: string) => boolean;
  isCurrentPaper: (p: Paper) => boolean;
  isPaperAvailableOffline: (pdfPath?: string) => Promise<boolean>;
};

export const AppContext = React.createContext<AppContextType>({
  addPaperToCollection: (_p: Paper, _c: Collection) => Promise.resolve(),
  addPaperToLibrary: (_p: PartialPaper | Paper) => Promise.resolve(),
  changeCurrentPaper: (_p: PartialPaper) => Promise.resolve(),
  clearLocalData: () => Promise.resolve(),
  clearTags: () => Promise.resolve(),
  deleteCollection: (_c: Collection) => Promise.resolve(),
  download: (_p: Paper, _pr?: (_r: number, _t: number) => void) =>
    Promise.resolve(),
  fetchPaper: (
      _p: Paper,
      _source?: string[],
      _forceRefresh?: boolean,
      _updateProgressFn?: (p: Paper, msg: string) => void,
      _updatePaperFn?: (p: Paper) => void,
  ) => Promise.resolve(_p),
  isCurrentPaper: (_p: Paper) => false,
  isDarkMode: () => false,
  isPaperAvailableOffline: (_?: string) => Promise.resolve(false),
  isPaperInLibrary: (_pid: string) => false,
  loadAppData: () => Promise.resolve(),
  newCollection: (_t: string) => Promise.resolve(createNewCollection()),
  onPaperOrderChanged: (_sortType) => {/* empty */},
  removePaperFromCollection: (_pid: string, _c: Collection) =>
    Promise.resolve(),
  removePaperFromLibrary: (_pid: string | string[]) => Promise.resolve(),
  removePdf: (_pid: string) => Promise.resolve(),
  resetTags: () => Promise.resolve({}),
  saveCollectionAndSync: () => Promise.resolve({} as Collection),
  savePaperAndSync: (_p: Paper, _sync = true) =>
    Promise.resolve({} as Paper),
  savePublicCollection: (_c: Collection) => Promise.resolve(),
  share: () => Promise.resolve(),
  sharePdf: (_p: Paper) => Promise.resolve(),
});

export type NavigationContextType = {
  showModal: (
    content: (close: () => void) => JSX.Element,
    options: ModalOptionsType
  ) => void;
  closeModal: () => void;
  appNavigation?: NavigationContainerRef<AppStackParamList>;
  navigate: (
      screen: keyof MainStackParamList,
      params?: Record<string, unknown>,
  ) => void;
}

export const NavigationContext = React.createContext<NavigationContextType>({
  closeModal: () => {/* empty */},
  navigate: (_screen, _params) => {/* empty */},
  showModal: (_content, _options: ModalOptionsType) => {/* empty */},
});
