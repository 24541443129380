import _ from 'lodash';
import React from 'react';
import {useRecoilState} from 'recoil';

import {
  ItemKind, PreferencesScreen,
} from '../../components/Preferences';
import {settingsState} from '../../recoil/atoms';


export const PdfViewer = (): JSX.Element => {
  const [settings, setSettings] = useRecoilState(settingsState);

  return (
    <PreferencesScreen
      sections={[
        {
          id: 'main',
          title: '',
          items: () => [
            {
              id: 'save_last_read',
              title: 'Save Last Read Page',
              kind: ItemKind.CheckBox,
              value: settings.saveLastReadPage,
              onPress: () => setSettings({
                ...settings,
                saveLastReadPage: !settings.saveLastReadPage}),
            },
            {
              id: 'scroll_direction',
              title: 'Horizontal Scrolling',
              kind: ItemKind.CheckBox,
              value: settings.pdfScrollDirection === 'horizontal',
              onPress: () => setSettings({
                ...settings,
                pdfScrollDirection:
                  settings.pdfScrollDirection === 'horizontal' ?
                  'vertical' : 'horizontal'}),
            },
          ],
        },
        {
          id: 'experimental',
          title: '',
          items: () => [
            {
              id: 'experimental',
              title: 'Use Experimental PDF Viewer',
              kind: ItemKind.CheckBox,
              value: settings.experimentalPdfViewer,
              adminOnly: true,
              onPress: () => setSettings({
                ...settings,
                experimentalPdfViewer: !settings.experimentalPdfViewer}),
            },
            {
              id: 'experimental',
              title: 'Use Experimental HTML Viewer',
              kind: ItemKind.CheckBox,
              value: !settings.useWebViewForHtml,
              adminOnly: true,
              onPress: () => setSettings({
                ...settings,
                useWebViewForHtml: !settings.useWebViewForHtml}),
            },
          ],
        },
      ]}
    />
  );
};
