import React, {useContext} from 'react';

import {NavigationContext} from '../context';
import {
  IconSort,
} from '../platform/icons';
import HeaderMenu from './HeaderMenu';


const MainHeaderMenu = () => {
  const {navigate} = useContext(NavigationContext);

  const menuItems = [];

  menuItems.push({
    title: 'Sort By',
    icon: IconSort,
    testID: 'btn_sort',
    onPress: async () => {
      navigate('Preferences', {screen: 'SortBy'});
    },
  });

  return <HeaderMenu headerMenuItems={menuItems}/>;
};

export default MainHeaderMenu;
