import {useNavigation, useRoute} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {useRecoilState} from 'recoil';

import Api from '../../common/api';
import Tag from '../../common/tag';
import {PaperTag} from '../../components/PaperTag';
import {ItemKind, PreferencesScreen} from '../../components/Preferences';
import {dialog} from '../../platform/dialog';
import {
  getIconByName,
  IconDelete,
  iconNames,
} from '../../platform/icons';
import {allTagsState} from '../../recoil/atoms';
import {SettingsStackParamList} from './Navigator';


export type TagDetailsParams = { tag: string };

export const TagDetails = () => {
  const route = useRoute();
  const [allTags, setAllTags] = useRecoilState(allTagsState);
  const [tag, setTag] = useState<Tag>();
  const naviation =
    useNavigation<StackNavigationProp<SettingsStackParamList>>();

  useEffect(() => {
    setTag(allTags[(route.params as TagDetailsParams).tag]);
  }, [route.params]);

  useEffect(() => {
    setTag(allTags[tag?.key || (route.params as TagDetailsParams).tag]);
  }, [allTags]);

  const onNameChanged = (val?: string) => {
    if (!val || !/^[a-z0-9\-:]+$/.test(val)) {
      alert(
          'Tag name is not valid. It can only contains lower-case letters, ' +
          'numbers, hyphens, and at most one semicolon.',
      );
    } else {
      if (tag) {
        Api.Tag.remove(tag);
        const newTag = {
          ...tag,
          key: val,
        };
        setAllTags(Object.fromEntries([
          ...Object.entries(allTags).filter(([k]) => k !== tag.key),
          [val, newTag],
        ]));
        Api.Tag.save(newTag);
      }
    }
  };

  const updateTag = async (newTag: Tag) => {
    if (!tag) return;
    setAllTags({
      ...allTags,
      [tag.key]: newTag,
    });
    await Api.Tag.save(newTag);
  };

  return (
    tag ? <PreferencesScreen
      sections={[
        {
          id: 'preview',
          items: () => [
            {
              id: 'preview',
              title: 'Preview',
              kind: ItemKind.Custom,
              pressable: false,
              component:
              <View
                style={{
                  flexDirection: 'row', justifyContent: 'center', flex: 1,
                  padding: 16}}>
                {tag && <PaperTag tag={tag} />}
              </View>,
            },
          ],
        },
        {
          id: 'general',
          title: '',
          items: () => [
            {
              id: 'name',
              kind: ItemKind.Input,
              title: 'Name',
              value: tag?.key,
              inline: true,
              onChange: (val) => onNameChanged(val as string),
            },
            {
              id: 'display_text',
              kind: ItemKind.Input,
              title: 'Display Text',
              value: tag?.text,
              inline: true,
              onChange: (val) => {
                updateTag({
                  ...tag,
                  text: val as string,
                });
              },
            },
            {
              id: 'icon',
              kind: ItemKind.Menu,
              title: 'Icon',
              subTitle: tag?.icon,
              onPress: () => naviation.navigate('TagIcon', {tag: tag?.key}),
            },
            {
              id: 'description',
              kind: ItemKind.Input,
              title: 'Description',
              value: tag?.description,
              onChange: (val) => {
                updateTag({
                  ...tag,
                  description: val as string,
                });
              },
            },
            {
              id: 'style',
              kind: ItemKind.Menu,
              title: 'Style',
              subTitle: tag?.style,
              onPress: () => naviation.navigate('TagStyle', {tag: tag?.key}),
            },
            {
              id: 'hidden',
              kind: ItemKind.CheckBox,
              title: 'Hidden',
              value: tag?.hidden,
              onPress: () => {
                updateTag({
                  ...tag,
                  hidden: !tag.hidden,
                });
              },
            },
            {
              id: 'display_priority',
              kind: ItemKind.Input,
              title: 'Display Priority',
              value: tag?.priority.toString(),
              inline: true,
              onChange: (val) => {
                if (tag) {
                  try {
                    updateTag({
                      ...tag,
                      priority: parseInt((val as string) || '0'),
                    });
                  } catch (e) {
                    alert('Display priority must be a number.');
                  }
                }
              },
            },
          ],
        },
        {
          id: 'others',
          title: 'Others',
          items: () => [
            {
              id: 'remove_tag',
              kind: ItemKind.Button,
              title: 'Remove Tag',
              icon: IconDelete,
              onPress: () =>
                dialog('Remove Tag', `Do you want to remove ${tag?.key}?`, [
                  {
                    text: 'Delete',
                    style: 'destructive',
                    onPress: () => {
                      if (tag) {
                        setAllTags(Object.fromEntries(Object.entries(allTags)
                            .filter(([key, _]) => key !== tag.key)));
                        Api.Tag.remove(tag).then(() => naviation.goBack());
                      }
                    },
                  },
                  {
                    text: 'Cancel',
                  },
                ]),
            },
          ],
        },
      ]}
    /> : <></>
  );
};

export const TagStyle = (): JSX.Element => {
  const [allTags, setAllTags] = useRecoilState(allTagsState);
  const [tag, setTag] = useState<Tag>();
  const route = useRoute();

  useEffect(() => {
    setTag(allTags[(route.params as TagDetailsParams).tag]);
  }, [route.params]);

  const styles = [
    {name: 'Primary', style: 'primary'},
    {name: 'Success', style: 'success'},
    {name: 'Warning', style: 'warning'},
    {name: 'Error', style: 'error'},
  ];

  const updateTag = async (newTag: Tag) => {
    if (!tag) return;
    setAllTags({
      ...allTags,
      [tag.key]: newTag,
    });
    await Api.Tag.save(newTag);
  };

  return (
    tag ? <PreferencesScreen
      sections={[{
        id: 'main',
        items: () =>
          styles.map((s) => ({
            id: s.name,
            kind: ItemKind.CheckBox,
            title: s.name,
            value: tag?.style === s.style,
            onPress: () => {
              updateTag({
                ...tag,
                style: s.style,
              });
            },
          })),
      }]
      }
    /> : <></>
  );
};

export const TagIconScreen = () => {
  const [allTags, setAllTags] = useRecoilState(allTagsState);
  const [tag, setTag] = useState<Tag>();
  const route = useRoute();

  useEffect(() => {
    setTag(allTags[(route.params as TagDetailsParams).tag]);
  }, [route.params]);

  const updateTag = async (newTag: Tag) => {
    if (!tag) return;
    setAllTags({
      ...allTags,
      [tag.key]: newTag,
    });
    setTag(newTag);
    await Api.Tag.save(newTag);
  };

  return (
    tag ? <PreferencesScreen
      sections={[{
        id: 'main',
        items: () => [
          {
            id: 'none',
            kind: ItemKind.CheckBox,
            title: 'none',
            value: tag?.icon === undefined,
            onPress: () => {
              updateTag({
                ...tag,
                icon: undefined,
              });
            },
          },
          ...iconNames.map((s) => ({
            id: s,
            kind: ItemKind.CheckBox,
            title: s,
            icon: getIconByName(s),
            value: tag?.icon === s,
            onPress: () => {
              updateTag({
                ...tag,
                icon: s,
              });
            },
          })),
        ]}]}
    /> : <></>
  );
};
