import {atom} from 'recoil';

import Collection from '../../common/collection';

export const allCollectionsState = atom<Collection[]>({
  key: 'AllCollections',
  default: [],
});

export const currentCollectionState = atom<Collection | undefined>({
  key: 'CurrentCollection',
  default: undefined,
});
