import * as Device from 'expo-device';
import {Platform} from 'react-native';

import {PlatformName} from '../common/platform';

export const platform = {
  name: PlatformName.ReactNative,
  enableOfflineAccess: false,
  enableSync: false,
  useLocalStorage: true,
  isElectron: false,
};

export const isWeb = Platform.OS === 'web';
export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isWindows = Platform.OS === 'windows';
export const isDevice = Device.isDevice;
