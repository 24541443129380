import {firestore} from '../../platform/firebase';
import {
  localStorageGetItem,
  localStorageGetKeys,
  localStorageRemoveItem,
  localStorageSetItem,
} from '../../platform/localStorage';
import Collection, {
  createNewCollection, getCollectionJSON, mergeCollection,
} from '../collection';
import Paper, {createNewPaper, refreshPaper} from '../paper';
import {uid, updateLastSynced} from './utils';

export type PublicPaper = {
  authors: string[];
  numCitations: number;
  pdfUrl: string;
  tags: string[];
  title: string;
  venue: string;
  year: string;
};

const removeLocal = async (c: Collection): Promise<void> => {
  await localStorageRemoveItem(`collection:${c.key}`);
};

const removeServer = async (c: Collection): Promise<void> => {
  if (!firestore) return;
  if (!uid()) return;
  await saveServer({
    ...c,
    deleted: true,
  } as Collection);
  await firestore.collection('Sync').doc(uid())
      .collection('Collections').doc(c.key).delete();
};

/**
 * Save a collection to local storage and remote server
 * @param collection - collection
 */
async function saveLocal(
    collection: Collection, keepDate = false): Promise<Collection> {
  if (collection.isPublic) console.warn('Cannot save public collection');
  const c = {
    ...collection,
    dateModified: keepDate ? collection.dateModified : Date.now(),
  } as Collection;
  // Save to local storage
  await localStorageSetItem(
      `collection:${c.key}`,
      JSON.stringify(getCollectionJSON(c)));
  console.log('Collection saved', c.key);
  return c;
}

/**
 * Save a collection to remote server
 * @param c - collection
 * @returns updated collection
 */
async function saveServer(c: Collection): Promise<void> {
  if (!uid()) return;
  if (c.isPublic) console.warn('Cannot save public collection');
  await firestore?.collection('Collections').doc(c.key).set({
    ...getCollectionJSON(c),
    ownedBy: uid(),
  });
  await firestore?.collection('Sync').doc(uid())
      .collection('Collections').doc(c.key)
      .set({dateModified: c.dateModified});

  await updateLastSynced();
  console.log('Collection saved to server', c.key);
}

const getLocal = async (cid: string): Promise<Collection | null> => {
  const json = await localStorageGetItem(`collection:${cid}`);
  const c = json ?
    createNewCollection({
      ...JSON.parse(json),
      key: cid,
    }) :
    null;

  // Migration code. TODO: remove after a few releases
  if (c) {
    if (Array.isArray(c.papers)) {
      c.paperIds = c?.papers as unknown as string[];
      await saveLocal(c, true);
    }
    if (!c.dateModified) {
      c.dateModified = Date.now();
      await saveLocal(c, true);
    }
  }

  return c;
};

const getServer = async (cid: string): Promise<Collection | null> => {
  if (!uid()) return null;
  if (!firestore) return null;
  try {
    const doc = await firestore.collection('Collections').doc(cid).get();
    const data = doc.data();
    return data ? createNewCollection({
      ...data,
      key: cid,
    }) as Collection : null;
  } catch (e: unknown) {
    console.log(e);
    return null;
  }
};

/**
 * Sync collections with server
 */
async function sync() {
  if (!uid()) return false;
  if (!firestore) return false;
  let updated = false;
  const dateModified = Object.fromEntries(
      (await firestore.collection('Sync').doc(uid())
          .collection('Collections').get())
          .docs.map((d) => ([
            d.id, d.data().dateModified as number | undefined])));

  const localCollectionKeys = (await localStorageGetKeys()).filter((key) =>
    key.startsWith('collection:')).map((key) => key.split(':')[1]);
  const allKeys = [...(
    new Set([...Object.keys(dateModified), ...localCollectionKeys]))];

  await Promise.all(allKeys.map(async (key) => {
    const localCollection = await getLocal(key);

    const remoteCollection = await getServer(key);

    if (!localCollection?.key && !remoteCollection?.key) {
      updated = true;
      return firestore?.collection('Sync').doc(uid())
          .collection('Collections').doc(key).delete();
    }

    // if the collection is marked as deleted
    if (remoteCollection?.deleted) {
      updated = true;
      if (localCollection) await removeLocal(localCollection);
      return firestore?.collection('Sync').doc(uid())
          .collection('Collections').doc(key).delete();
    }

    if (dateModified[key] && (!remoteCollection)) {
      // remote collection does not exist but an entry exists in Sync
      updated = true;
      return firestore?.collection('Sync').doc(uid())
          .collection('Collections').doc(key).delete();
    }

    if (!localCollection ||
        (localCollection.dateModified || 0) < (dateModified[key] || 0)) {
      // remote collection is newer or local collection does not exist
      updated = true;
      const updatedCollection = mergeCollection(
          localCollection || createNewCollection(), remoteCollection);
      console.log('local collection updated', key);
      await saveLocal(updatedCollection, true);
    } else if (!dateModified[key] ||
        (localCollection.dateModified || 0) > (dateModified[key] || 0)) {
      // remote collection is older or does not have a dateModified
      updated = true;
      console.log('remote collection updated', key);
      await saveServer(localCollection);
    }
  }));
  return updated;
}

/**
 * @param numItems - manimum number of returned collections
 * @returns list of public collections
 */
async function getPublicCollections(
    limit = 10,
    offset = 0,
): Promise<Collection[]> {
  const endpoint = 'https://papershelf-node.azurewebsites.net/api';
  const response = await fetch(
      `${endpoint}/QueryPublicCollection?` +
      new URLSearchParams({
        limit: limit.toString(),
        offset: offset.toString(),
      }).toString());
  return await Promise.all(
      (await response.json()).map(async (c: Record<string, unknown>) => {
        // let imageUrl = undefined;
        // try {
        //   imageUrl = c.image ?
        //   ((await storage
        //       ?.ref('collections')
        //       .child('images')
        //       .child(c.image as string)
        //       .getDownloadURL()) as string) :
        //   undefined;
        // } catch (e) {
        // // Do nothing
        // }
        return createNewCollection({
          key: c.id,
          ...c,
          isPublic: true,
        });
      }),
  );
}

/**
 * get all public collection papers
 * @param cid - collection id
 */
async function getPublicCollectionPapers(cid: string, limit = 10, offset = 0) {
  const endpoint = 'https://papershelf-node.azurewebsites.net/api';
  const response = await fetch(
      `${endpoint}/GetPublicCollectionPapers?` +
      `id=${cid}&limit=${limit}&offset=${offset}`);
  const papers = await response.json() as Paper[];
  return papers.map((p) => refreshPaper({
    ...createNewPaper(),
    ...p} as Paper));
}

// /**
//  * @param key - public collection id
//  * @returns a public collection specified by `key`
//  */
// async function getPublicCollection(key: string): Promise<PublicCollection> {
//   const snapshot = await db?.ref('collections').child(key).once('value');
//   const data = snapshot?.val() as Record<string, unknown>;
//   const imageUrl = data.image ?
//     ((await storage
//         ?.ref('collections')
//         .child('images')
//         .child(data.image as string)
//         .getDownloadURL()) as string) :
//     undefined;
//   return {
//     key,
//     imageUrl,
//     ...data,
//   } as PublicCollection;
// }

// /**
//  * @returns list of groups of public collections
//  */
// async function getPublicCollectionGroups(): Promise<PublicCollectionGroup[]>
// {
//   const snapshot = await db?.ref('collectionGroups').once('value');
//   const data = snapshot?.val() as Record<string, Record<string, unknown>>;
//   return Object.entries(data).map(
//       ([key, c]) => ({key, ...c} as PublicCollectionGroup),
//   );
// }

export default {
  save: saveLocal,
  load: getLocal,
  server: {
    save: saveServer,
    get: getServer,
    remove: removeServer,
  },
  local: {
    save: saveLocal,
    get: getLocal,
    remove: removeLocal,
  },
  sync,
  // publish,
  // unpublish,
  getPublicCollectionPapers,
  getPublicCollections,
  // getPublicCollection,
  // getPublicCollectionGroups,
};
