import {selector} from 'recoil';

import Paper, {
  createNewPaper, paperSearchKeys, PartialPaper, sortPapers,
} from '../../common/paper';
import {ListDisplayType} from '../../context';
import {
  allPapersState,
  currentCollectionState,
  listDisplayTypeState,
  paperFuseState,
  searchQueryState,
  sortByState,
} from '../atoms';

export const paperListItemsState = selector<PartialPaper[]>({
  key: 'PaperListItems',
  get: ({get}) => {
    console.log('refresh paper list items');
    const listDisplayType = get(listDisplayTypeState);
    if (listDisplayType === ListDisplayType.WebSearch) return [];
    const allPapers = get(allPapersState);
    const currentCollection = get(currentCollectionState);
    const sortBy = get(sortByState);

    let papers: PartialPaper[] = [];
    if (!currentCollection ||
        listDisplayType === ListDisplayType.LibrarySearch) {
      papers = Object.values(allPapers);
    } else {
      papers = currentCollection.paperIds.map((pid) => {
        if (allPapers[pid]) return allPapers[pid];
        else if (currentCollection.papers[pid]) {
          return {
            ...createNewPaper(),
            ...(currentCollection.papers[pid] as Paper),
            id: pid,
          } as PartialPaper;
        }
      }).filter((p) => !!p) as PartialPaper[];
    }
    return sortPapers(papers, sortBy);
  },
});

export const paperSearchListItemsState = selector<PartialPaper[]>({
  key: 'PaperSearchListItems',
  get: ({get}) => {
    const searchQuery = get(searchQueryState);
    const fuse = get(paperFuseState);
    const paperListItems = get(paperListItemsState);
    if (!searchQuery) return paperListItems;
    const results = fuse.search(`'"${searchQuery}"`);
    const papers = results.map((r) => {
      return {
        ...r.item,
        matches: Object.fromEntries(
            paperSearchKeys.map((k) => {
              const match = r.matches?.find((m) => m.key === k);
              return [k, match && {
                indices: match.indices,
                text: match.value,
              }];
            })),
      } as PartialPaper;
    });
    return papers;
  },
});
