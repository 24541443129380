import {Text, Tooltip, TooltipProps, useTheme} from '@rneui/themed';
import React, {useState} from 'react';
import {
  Pressable,
  View,
} from 'react-native';

import {
  IconMore,
  IconProps,
} from '../platform/icons';

export type HeaderMenuItemProps = {
  title: string;
  icon?: (props: IconProps) => React.ReactNode;
  testID?: string;
  onPress: () => void;
  disabled?: boolean;
  noBottomBorder?: boolean;
};

export const HeaderMenuItem = ({
  title,
  icon,
  testID,
  onPress,
  disabled,
  noBottomBorder = false,
}: HeaderMenuItemProps) => {
  const {theme} = useTheme();
  return <Pressable
    onPress={onPress}
    testID={testID}
    disabled={disabled}
    style={{
      justifyContent: 'center',
      borderBottomWidth: noBottomBorder ? 0 : 1,
      borderBottomColor: theme.colors.border,
    }}>
    {({pressed}) => (<View style={{
      paddingHorizontal: 16,
      alignItems: 'center',
      height: 48,
      flexDirection: 'row',
      backgroundColor: pressed ?
        theme.colors.backgroundHighlight : theme.colors.white}}>
      {icon && <View style={{paddingRight: 12}}>{icon({size: 16})}
      </View>}
      <Text style={{
        color: disabled ? theme.colors.disabled : theme.colors.black,
        fontSize: 16,
      }}>{title}</Text>
    </View>)}
  </Pressable>;
};

const ControlledTooltip: React.FC<TooltipProps & {
  children: React.ReactNode
}> = (props) => {
  return (
    <Tooltip
      {...props}
    />
  );
};

const HeaderMenu: React.FC<TooltipProps & {
  headerMenuItems: HeaderMenuItemProps[],
  content?: JSX.Element,
}> =
(props) => {
  const [open, setOpen] = useState(false);
  const {theme} = useTheme();

  const close = () => {
    setOpen(false);
  };

  const popover = <View style={{flexDirection: 'column', width: '100%'}}>
    {props.headerMenuItems.map(
        (props, id) => <HeaderMenuItem key={id} {...props} onPress={() => {
          props.onPress();
          close();
        }} />)}
  </View>;

  return <ControlledTooltip
    // ModalComponent={Modal}
    visible={open}
    onOpen={() => {
      setOpen(true);
    }}
    onClose={close}
    popover={popover}
    width={200}
    height={48 * props.headerMenuItems.length}
    withPointer={true}
    backgroundColor={theme.colors?.white}
    withOverlay={false}
    animationType="none"
    containerStyle={{
      borderColor: theme.colors.border,
      borderWidth: 1,
      borderRadius: 0,
      width: 200,
      backgroundColor: theme.colors.white,
      alignContent: 'flex-start',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: 0,
    }}
    {...props}>
    {props.content || <View style={{width: 32}}>
      <IconMore />
    </View>}
  </ControlledTooltip>;
};

export default HeaderMenu;
