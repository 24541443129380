import {db} from '../../platform/firebase';
import Collection from './collection';
import News, {NewsItem} from './news';
import Note from './note';
import Paper from './paper';
import Profile, {UserInfo} from './profile';
import Settings from './settings';
import Tag from './tag';
import {
  getLocalLastUpdated,
  getServerLastUpdated,
  isLoggedIn,
  uid,
  updateLastSynced,
} from './utils';

export type {NewsItem, UserInfo};

export default {
  Collection,
  Settings,
  Paper,
  Note,
  Tag,
  Profile,
  News,
  isLoggedIn,
  updateLastSynced,
  getLocalLastUpdated,
  getServerLastUpdated,
  uid,
  db,
};
