import Fuse from 'fuse.js';
import {atom} from 'recoil';

import Paper, {PartialPaper} from '../../common/paper';
import {defaultSettings, SortType} from '../../common/store';
import {ListDisplayType} from '../../context';

export const allPapersState = atom<Record<string, PartialPaper>>({
  key: 'AllPapers',
  default: {},
});

export const currentPaperState = atom<Paper | null>({
  key: 'CurrentPaper',
  default: null,
});

export const listDisplayTypeState = atom<ListDisplayType>({
  key: 'ListDisplayType',
  default: ListDisplayType.Regular,
});

export const isFetchingPaperState = atom<Record<string, boolean>>({
  key: 'IsFetchingPaper',
  default: {},
});

export const isDownloadingPaperState = atom<Record<string, boolean>>({
  key: 'IsDownloadingPaper',
  default: {},
});

export const isSearchModeState = atom<boolean>({
  key: 'IsSearchMode',
  default: false,
});

export const searchQueryState = atom<string>({
  key: 'SearchQuery',
  default: '',
});

export const webSearchQueryState = atom<string>({
  key: 'WebSearchQuery',
  default: '',
});

export const sortByState = atom<SortType>({
  key: 'SortBy',
  default: defaultSettings.paperList.sortBy,
});

export const paperFuseState = atom<Fuse<PartialPaper>>({
  key: 'PaperFuse',
  default: undefined,
});
