import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {useTheme} from '@rneui/themed';
import React, {useContext, useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';

import Collection, {createNewCollection} from '../../common/collection';
import {ItemKind, PreferencesScreen} from '../../components/Preferences';
import {AppContext} from '../../context';
import {dialog} from '../../platform/dialog';
import {IconAdd} from '../../platform/icons';
import {allCollectionsState, currentCollectionState} from '../../recoil/atoms';
import {SettingsStackParamList} from './Navigator';

export const CollectionDetails = ({
  goBack,
  c,
}: {
  goBack?: () => void;
  c?: Collection;
}): JSX.Element => {
  const route =
    useRoute<RouteProp<SettingsStackParamList, 'CollectionDetails'>>();
  const {
    deleteCollection,
    saveCollectionAndSync,
  } = useContext(AppContext);
  const [collection, setCollection] = useState<Collection>();
  const [allCollections, setAllCollections] =
    useRecoilState(allCollectionsState);
  const [currentCollection, setCurrentCollection] =
    useRecoilState(currentCollectionState);
  const naviation = useNavigation();
  const {theme} = useTheme();

  useEffect(() => {
    if (c) {
      setCollection(c);
    }
  });

  useEffect(() => {
    if (c) return;
    if (!route.params?.collectionKey) {
      setCollection(createNewCollection({name: 'New Collection'}));
    } else {
      setCollection(
          allCollections.find((c) => c.key === route.params.collectionKey),
      );
    }
  }, [route.params]);

  const onDeleteCollection = () => {
    dialog(
        'Delete Collection',
        `Do you want to delete ${collection?.name}? This cannot be undone.`,
        [
          {
            text: 'Delete',
            style: 'destructive',
            onPress: async () => {
              if (!collection) return;
              setAllCollections(
                  allCollections.filter((c) => c.key !== collection.key),
              );
              if (currentCollection?.key === collection.key) {
                setCurrentCollection(undefined);
              }
              // await Api.Collection.unpublish(collection);
              await deleteCollection(collection);
            goBack ? goBack() : naviation.goBack();
            },
          },
          {
            text: 'Cancel',
          },
        ],
    );
  };

  const changeCollection = (newCollection: Collection) => {
    if (!collection) return;
    setAllCollections(allCollections.map(
        (c) => c.key === collection.key ? newCollection : c));
    setCollection(newCollection);
    saveCollectionAndSync(newCollection);
  };

  return (
    <PreferencesScreen
      sections={[
        {
          id: 'general',
          title: '',
          items: () => [
            {
              id: 'name',
              kind: ItemKind.Input,
              title: 'Name',
              value: collection?.name,
              inline: true,
              onChange: (val) => {
                changeCollection({
                  ...collection,
                  name: (val as string || ''),
                } as Collection);
              },
            },
            {
              id: 'description',
              kind: ItemKind.Input,
              title: 'Description',
              value: collection?.description,
              numberOfLines: 2,
              onChange: (val) => {
                changeCollection({
                  ...collection,
                  description: (val as string || ''),
                } as Collection);
              },
            },
            {
              id: 'starred',
              kind: ItemKind.CheckBox,
              title: 'Starred',
              value: collection?.starred,
              onChange: (_val) => {
                changeCollection({
                  ...collection,
                  starred: !collection?.starred,
                } as Collection);
              },
            },
            {
              id: 'hidden',
              kind: ItemKind.CheckBox,
              title: 'Hidden',
              value: collection?.hidden,
              onChange: (_val) => {
                changeCollection({
                  ...collection,
                  hidden: !collection?.hidden,
                } as Collection);
              },
            },
          ],
        },
        {
          id: 'public',
          title: '',
          adminOnly: true,
          items: () => [
            {
              id: 'public',
              kind: ItemKind.CheckBox,
              title: 'Public',
              value: collection?.isPublic || collection?.publicCollectionKey,
              subTitle: 'Whether to list in public collections',
              disabled: true,
            },
            {
              id: 'publish',
              kind: ItemKind.Button,
              title: 'Publish',
              subTitle: 'Last Published: Never',
              value: collection?.isPublic || collection?.publicCollectionKey,
              disabled: true,
            },
          ],
        },
        {
          id: 'share',
          title: '',
          adminOnly: true,
          items: () => [
            {
              id: 'share',
              kind: ItemKind.CheckBox,
              title: 'Share',
              subTitle: 'Whether to share with other users.',
              value: false,
            },
            {
              id: 'can_view',
              kind: ItemKind.List,
              title: 'Can View',
              value: collection?.isPublic || collection?.publicCollectionKey,
              subItems: () => [
                {
                  id: 'add_can_view',
                  kind: ItemKind.Button,
                  title: 'Add User',
                  icon: IconAdd,
                },
              ],
            },
            {
              id: 'can_edit',
              kind: ItemKind.List,
              title: 'Can Edit',
              value: collection?.isPublic || collection?.publicCollectionKey,
              subItems: () => [
                {
                  id: 'add_can_view',
                  kind: ItemKind.Button,
                  title: 'Add User',
                  icon: IconAdd,
                },
              ],
            },
          ],
        },
        {
          id: 'actions',
          title: '',
          items: () => [
            {
              id: 'delete',
              kind: ItemKind.Button,
              title: 'Delete',
              color: theme.colors.error,
              onPress: onDeleteCollection,
            },
          ],
        },
        {
          id: 'admin',
          title: 'Admin',
          adminOnly: true,
          items: () => [
            {
              id: 'key',
              kind: ItemKind.Text,
              title: 'Key',
              subTitle: collection?.key,
              inline: true,
              clickToCopy: true,
            },
            {
              id: 'public_collection_key',
              kind: ItemKind.Text,
              title: 'Public Col.',
              subTitle: collection?.publicCollectionKey,
              inline: true,
              clickToCopy: true,
            },
            {
              id: 'shared_collection_key',
              kind: ItemKind.Text,
              title: 'Shared Col.',
              subTitle: collection?.sharedCollectionKey,
              inline: true,
              clickToCopy: true,
            },
          ],
        },
      ]}
    />
  );
};
