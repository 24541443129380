import * as Device from 'expo-device';
import {Platform} from 'react-native';
import {selector} from 'recoil';

import {Layout} from '../../common/store';
import {deviceTypeState, windowDimensionState} from '../atoms';

export const isPhoneState = selector<boolean>({
  key: 'IsPhone',
  get: ({get}) => {
    const deviceType = get(deviceTypeState);
    return deviceType === Device.DeviceType.PHONE;
  },
});

export const isElectronState = selector<boolean>({
  key: 'IsElectron',
  get: () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!window.electron;
  },
});

export const isPadState = selector<boolean>({
  key: 'IsPad',
  get: ({get}) => {
    const deviceType = get(deviceTypeState);
    return deviceType === Device.DeviceType.TABLET;
  },
});

export const isTabletState = selector<boolean>({
  key: 'IsTablet',
  get: ({get}) => {
    const deviceType = get(deviceTypeState);
    return deviceType === Device.DeviceType.TABLET;
  },
});

export const isWebState = selector<boolean>({
  key: 'IsWeb',
  get: () => {
    return Platform.OS === 'web';
  },
});

export const useModalState = selector<boolean>({
  key: 'UseModal',
  get: ({get}) => {
    // return get(windowDimensionState).width > 600;
    if (get(layoutState) === 'one-column') return false;
    return get(isWebState);
  },
});

export const layoutState = selector<Layout>({
  key: 'LayoutState',
  get: ({get}) => {
    // const layout = get(settingsState).layout;
    // if (layout !== 'auto') return layout;
    if (get(windowDimensionState).width < 600) return 'one-column';
    if (get(isPhoneState)) return 'one-column';
    if (get(isPadState)) return 'two-column';
    if (get(isWebState)) return 'three-column';
    return 'one-column';
  },
});
