import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {ListItem} from '@rneui/base';
import {Text, useTheme} from '@rneui/themed';
import React, {useContext} from 'react';
import {View} from 'react-native';
import {useRecoilState} from 'recoil';

import Api from '../../common/api';
import {createNewTag, tagCompareFn} from '../../common/tag';
import {PaperTag} from '../../components/PaperTag';
import {ItemKind, PreferencesScreen} from '../../components/Preferences';
import {AppContext} from '../../context';
import {dialog} from '../../platform/dialog';
import {
  IconClear,
  IconDownload,
  IconEyeOff,
  IconNewTag,
} from '../../platform/icons';
import {toastError} from '../../platform/toast';
import {allTagsState} from '../../recoil/atoms';
import {SettingsStackParamList} from './Navigator';


export const Tags = (): JSX.Element => {
  const {resetTags, clearTags} = useContext(AppContext);
  const [allTags, setAllTags] = useRecoilState(allTagsState);
  const navigation =
    useNavigation<StackNavigationProp<SettingsStackParamList>>();
  const {theme} = useTheme();

  const updateTags = () => {
    const updateTagsFn = () => {
      resetTags()
          .catch(() =>
            toastError(
                'There is an error with updating tags. Please try again later.',
            ),
          );
    };
    dialog(
        'Use Default Tags',
        `If you have customized settings for some default tags, they will be ` +
        `overriden. Other tags will remain.`,
        [
          {
            text: 'Use Default',
            onPress: updateTagsFn,
          },
          {
            text: 'Cancel',
            style: 'cancel',
          },
        ],
    );
  };

  const removeAllTags = () => {
    const removeAllTagsFn = () => {
      clearTags()
          .catch(() => toastError('There was an error with clearing tags.'));
    };
    dialog('Clear Tags', `Do you want to clear all tags?`, [
      {
        text: 'Clear',
        onPress: removeAllTagsFn,
      },
      {
        text: 'Cancel',
        style: 'cancel',
      },
    ]);
  };

  return (
    <View style={{flex: 1}}>
      <PreferencesScreen
        sections={[
          {
            id: 'actions',
            title: '',
            items: () => [
              {
                id: 'intro',
                title: '',
                kind: ItemKind.Custom,
                pressable: false,
                component: (
                  <Text style={{padding: 16}}>
                    Tags can be used to describe a paper. Many tags are
                    automatically added from the metadata. You can
                    choose which tags to show with the paper and customize their
                    appearance.
                  </Text>
                ),
              },
              {
                id: 'new_tag',
                kind: ItemKind.Button,
                title: 'New Tag',
                icon: IconNewTag,
                onPress: () => {
                  if (!allTags['new-tag']) {
                    const tag = createNewTag({key: 'new-tag'});
                    setAllTags({...allTags, 'new-tag': tag});
                    Api.Tag.save(tag);
                  }
                  navigation.navigate('TagDetails', {tag: 'new-tag'});
                },
              },
              {
                id: 'remove',
                kind: ItemKind.Button,
                title: 'Remove All',
                subTitle: '',
                onPress: removeAllTags,
                icon: IconClear,
                hidden: true,
              },
              {
                id: 'reset',
                kind: ItemKind.Button,
                title: 'Use Default',
                subTitle: '',
                onPress: updateTags,
                icon: IconDownload,
              },
            ],
          },
          {
            id: 'tags',
            title: '',
            items: () =>
              Object.values(allTags)
                  .sort(tagCompareFn)
                  .map((t) => ({
                    id: t.key,
                    kind: ItemKind.Menu,
                    title: (
                      <View style={{
                        flexDirection: 'row', alignItems: 'center'}}>
                        <ListItem.Title style={{
                          flex: 1, color: theme.colors?.black,
                        }}>
                          {t.key}
                        </ListItem.Title>
                        <PaperTag tag={t} />
                      </View>
                    ),
                    subTitle: t.description,
                    onPress: () => navigation.navigate(
                        'TagDetails', {tag: t.key}),
                    icon: t.hidden ? IconEyeOff : undefined,
                  })),
          },
        ]}
      />
    </View>
  );
};
