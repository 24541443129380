import * as Crypto from 'expo-crypto';
import _uuid from 'react-native-uuid';

/**
 * Generate a unique id.
 * @returns string of a unique id
 */
export function uuid(): string {
  return _uuid.v4() as string;
}

/**
 * @param s - string to has
 * @returns hashed string
 */
export function md5(s: string): Promise<string> {
  return Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, s);
}
