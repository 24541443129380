import Realm, {UpdateMode} from 'realm';

import {isWeb} from '../../platform';
import {
  localStorageGetItem,
  localStorageRemoveItem,
  localStorageSetItem,
} from '../../platform/localStorage';
import {Note} from '../note';
import {RealmNote} from '../realm';

/**
 * save note locally
 * @param note - note
 */
async function saveLocal(note: Note, realm?: Realm): Promise<Note> {
  const n = {...note, dateModified: Date.now()} as Note;
  if (isWeb) {
    await localStorageSetItem(`note:${n.id}`, JSON.stringify(n));
  } else {
    if (!realm) return note;
    realm.write(() => {
      realm.create(RealmNote,
          n, UpdateMode?.Modified || 'modified');
    });
  }
  return n;
}

/**
 * load note from local storage
 */
async function loadFromLocalStorage(noteId: string): Promise<Note | undefined> {
  const json = await localStorageGetItem(`note:${noteId}`);
  if (!json) return undefined;
  return JSON.parse(json) as Note;
}

/**
 * Load note from local storage / realm
 * @param noteId - note id
 * @param realm - realm
 * @returns `Note` object
 */
async function loadLocal(
    noteId: string, realm?: Realm,
): Promise<Note | undefined> {
  if (isWeb) return loadFromLocalStorage(noteId);
  if (!realm) return undefined;
  const realmNote = realm.objectForPrimaryKey(RealmNote, noteId);
  if (!realmNote) return undefined;
  return {
    id: realmNote.id,
    title: realmNote.title,
    content: realmNote.content,
    collectionKey: realmNote.collectionKey,
    paperId: realmNote.paperId,
    dateModified: realmNote.dateModified,
  } as Note;
}

/**
 * Remove a paper in local storage and server.
 * @param pid - paper id
 */
async function removeLocal(noteId: string, realm?: Realm): Promise<void> {
  if (isWeb) {
    await localStorageRemoveItem(`note:${noteId}`);
  } else {
    if (!realm) return;
    realm.write(() => {
      const note = realm.objectForPrimaryKey('Note', noteId);
      realm.delete(note);
    });
  }
}

export default {
  local: {
    save: saveLocal,
    load: loadLocal,
    remove: removeLocal,
  },
};
