import Toast from 'react-native-root-toast';

/**
 * Show a toast with a success message
 * @param msg - message
 */
export function toastSuccess(msg: string): void {
  return Toast.show(msg, {
    duration: Toast.durations.SHORT,
    hideOnPress: true,
  });
}

/**
 * Show a toast with an error message
 * @param msg - message
 * @param e - error
 */
export function toastError(msg: string, e: unknown = undefined): void {
  Toast.show(msg);
  console.error(e);
  if (__DEV__) throw e;
}


/**
 * Show a toast
 * @param msg - message
 */
export function showToast(msg: string) {
  return Toast.show(msg, {
    duration: Toast.durations.LONG,
    hideOnPress: false,
  });
}


/**
 * Hide toast
 */
export function hideToast(toast: unknown): void {
  Toast.hide(toast);
}
