import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import _ from 'lodash';
import React, {useContext} from 'react';
import {useRecoilState, useRecoilValue} from 'recoil';

import Api from '../../common/api';
import {defaultSettings, SortType} from '../../common/store';
import {
  ItemKind, PreferenceItemProps, PreferencesScreen,
} from '../../components/Preferences';
import {AppContext} from '../../context';
import {dialog} from '../../platform/dialog';
import {toastSuccess} from '../../platform/toast';
import {settingsState} from '../../recoil/atoms';
import {isPhoneState} from '../../recoil/selectors';
import {SettingsStackParamList} from './Navigator';

export const GeneralTheme = (): JSX.Element => {
  const [settings, setSettings] = useRecoilState(settingsState);
  return (
    <PreferencesScreen
      sections={[{
        id: 'main',
        items: () => [
          {
            id: 'default',
            title: 'Default',
            kind: ItemKind.CheckBox,
            value: settings.theme === 'default',
            onChange: (val) =>
              val && setSettings({...settings, theme: 'default'}),
          },
          {
            id: 'light',
            title: 'Light',
            kind: ItemKind.CheckBox,
            value: settings.theme === 'light',
            onChange: (val) =>
              val && setSettings({...settings, theme: 'light'}),
          },
          {
            id: 'dark',
            title: 'Dark',
            kind: ItemKind.CheckBox,
            value: settings.theme === 'dark',
            onChange: (val) => val && setSettings({...settings, theme: 'dark'}),
          },
        ],
      }]}
    />
  );
};

export const GeneralLayout = (): JSX.Element => {
  const isPhone = useRecoilValue(isPhoneState);
  const [settings, setSettings] = useRecoilState(settingsState);
  return (
    <PreferencesScreen
      sections={[
        {
          id: 'main',
          items: () => [
            {
              id: 'auto',
              title: 'Default',
              kind: ItemKind.CheckBox,
              value: settings.layout === 'auto',
              onChange: (val) =>
                val && setSettings({...settings, layout: 'auto'}),
            },
            {
              id: 'single',
              title: 'Single',
              kind: ItemKind.CheckBox,
              value: settings.layout === 'one-column',
              onChange: (val) =>
                val && setSettings({...settings, layout: 'one-column'}),
            },
            {
              id: 'two',
              title: 'Two Columns',
              disabled: isPhone,
              kind: ItemKind.CheckBox,
              value: settings.layout === 'two-column',
              onChange: (val) => val && setSettings(
                  {...settings, layout: 'two-column'}),
            },
            {
              id: 'three',
              title: 'Three Columns',
              disabled: isPhone,
              kind: ItemKind.CheckBox,
              value: settings.layout === 'three-column',
              onChange: (val) => val && setSettings(
                  {...settings, layout: 'three-column'}),
            },
          ],
        }]}
    />
  );
};

export const sortByText = {
  [SortType.ByDateOpened]: 'Last Opened',
  [SortType.ByDateModified]: 'Last Modified',
  [SortType.ByDateAdded]: 'Last Added',
  [SortType.ByTitle]: 'Title',
  [SortType.ByCitation]: 'Citation',
  [SortType.ByYear]: 'Year',
};

export const GeneralSortBy = ({
  onChange,
}: {
  onChange?: (value: SortType) => void;
}): JSX.Element => {
  const [settings, setSettings] = useRecoilState(settingsState);
  const setSortBy = (sb: SortType) => {
    setSettings({
      ...settings,
      paperList: {
        ...settings.paperList,
        sortBy: sb,
      },
    });
    onChange && onChange(sb);
  };

  return (
    <PreferencesScreen
      sections={[
        {
          id: 'sorted_by',
          items: () => [
            {
              id: 'date_opened',
              kind: ItemKind.CheckBox,
              title: 'Last Opened',
              value: settings.paperList.sortBy === SortType.ByDateOpened,
              // icon: sort === SortType.ByDateModified ? <AcceptIcon /> : null,
              onPress: () => setSortBy(SortType.ByDateOpened),
            },
            {
              id: 'by_modified_date',
              kind: ItemKind.CheckBox,
              title: 'Last Modified',
              value: settings.paperList.sortBy === SortType.ByDateModified,
              // icon: sort === SortType.ByDateModified ? <AcceptIcon /> : null,
              onPress: () => setSortBy(SortType.ByDateModified),
            },
            {
              id: 'by_added_date',
              kind: ItemKind.CheckBox,
              title: 'Added Date',
              value: settings.paperList.sortBy === SortType.ByDateAdded,
              // icon: sort === SortType.ByDateAdded ? <AcceptIcon /> : null,
              onPress: () => setSortBy(SortType.ByDateAdded),
            },
            {
              id: 'by_year',
              kind: ItemKind.CheckBox,
              title: 'Year Published',
              value: settings.paperList.sortBy === SortType.ByYear,
              // icon: sort === SortType.ByYear ? <AcceptIcon /> : null,
              onPress: () => setSortBy(SortType.ByYear),
            },
            {
              id: 'by_citations',
              kind: ItemKind.CheckBox,
              title: 'Citation',
              value: settings.paperList.sortBy === SortType.ByCitation,
              // icon: sort === SortType.ByCitation ? <AcceptIcon /> : null,
              onPress: () => setSortBy(SortType.ByCitation),
            },
            {
              id: 'by_title',
              kind: ItemKind.CheckBox,
              title: 'Title',
              value: settings.paperList.sortBy === SortType.ByTitle,
              // icon: sort === SortType.ByTitle ? <AcceptIcon /> : null,
              onPress: () => setSortBy(SortType.ByTitle),
            },
          ],
        },
      ]}
    />
  );
};

export const General = (): JSX.Element => {
  const {
    clearLocalData,
  } = useContext(AppContext);
  const [settings, setSettings] = useRecoilState(settingsState);
  const isPhone = useRecoilValue(isPhoneState);
  const navigation =
    useNavigation<StackNavigationProp<SettingsStackParamList>>();

  return (
    <PreferencesScreen
      sections={[
        {
          id: 'general',
          title: '',
          items: () => [
            {
              id: 'theme',
              title: 'Theme',
              kind: ItemKind.Menu,
              onPress: () => navigation.navigate('GeneralTheme'),
            },
            {
              id: 'show_welcome',
              title: 'Show Welcome Screen',
              kind: ItemKind.CheckBox,
              value: settings.shouldShowWelcome,
              onPress: () => {
                setSettings({
                  ...settings, shouldShowWelcome: !settings.shouldShowWelcome});
              },
            },
            ...(isPhone ? [] : [{
              id: 'layout',
              title: 'Layout',
              kind: ItemKind.Menu,
              onPress: () => navigation.navigate('GeneralLayout'),
              adminOnly: true,
            } as PreferenceItemProps]),
          ],
        },
        {
          id: 'others',
          title: '',
          items: () => [
            {
              id: 'reset',
              kind: ItemKind.Button,
              title: 'Reset Settings',
              subTitle: 'Restore settings to their original defaults.',
              onPress: () => {
                dialog(
                    'Reset Settings',
                    `Do you want to set settings to default values? This ` +
                    `does not include app data such as papers, collections, ` +
                    `or tags`,
                    [
                      {
                        text: 'Reset',
                        style: 'destructive',
                        onPress: () => {
                          Api.Settings.save(defaultSettings).then(() => {
                            toastSuccess('Settings reset to default.');
                            setSettings(_.cloneDeep(defaultSettings));
                          });
                        },
                      },
                      {
                        text: 'Cancel',
                      },
                    ],
                );
              },
            },
            {
              id: 'clear_data',
              kind: ItemKind.Button,
              title: 'Clear Data',
              subTitle: 'Clear all data, including papers, collections, and ' +
                'tags.',
              onPress: () => {
                dialog(
                    'Clear Data',
                    `Do you want to clear all data?`,
                    [
                      {
                        text: 'Clear',
                        style: 'destructive',
                        onPress: async () => {
                          await clearLocalData();
                        },
                      },
                      {
                        text: 'Cancel',
                      },
                    ],
                );
              },
            },
          ],
        },
      ]}
    />
  );
};
