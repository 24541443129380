import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {useTheme} from '@rneui/themed';
import moment from 'moment';
import {SourceKey, Sources} from 'paper-fetch';
import React, {useContext, useState} from 'react';
import {
  Linking,
  View,
} from 'react-native';
import {useRecoilValue} from 'recoil';

import Collection, {createNewCollection} from '../../common/collection';
import Paper, {
  getCitationPapers,
  getReferencePapers,
} from '../../common/paper';
import ActivityIndicator from '../../components/ActivityIndicator';
import {
  ItemKind,
  PreferenceItemProps,
  PreferencesScreen,
} from '../../components/Preferences';
import {AppContext, NavigationContext} from '../../context';
import {MainStackParamList} from '../../Main';
import {dialog} from '../../platform/dialog';
import {
  IconDownload,
  IconError,
  IconRefresh,
  IconSuccess,
} from '../../platform/icons';
import {isFetchingPaperState} from '../../recoil/atoms';
import {PaperInfoContext} from './ContextProvider';
import {
  Ar5iv,
  ArXiv,
  CrossRef,
  OpenReview,
  PaperShelf,
  SemanticScholar,
} from './SourceMetadata';


const Metadata = () => {
  const {
    fetchPaper, isPaperInLibrary, savePaperAndSync} = useContext(AppContext);
  const {showModal} = useContext(NavigationContext);
  const isFetching = useRecoilValue(isFetchingPaperState);
  const [fetchingProgressText, setFetchingProgressText] = useState<string>('');
  const {theme} = useTheme();
  const {paper, setPaper} = useContext(PaperInfoContext);
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();

  const [isLoading, setIsLoading] = useState<Record<SourceKey, boolean>>({
    arxiv: false,
    ar5iv: false,
    semanticScholar: false,
    paperShelf: false,
    openReview: false,
    crossRef: false,
  });

  const sourceViews = {
    arxiv: <ArXiv paper={paper} />,
    ar5iv: <Ar5iv paper={paper} />,
    semanticScholar: <SemanticScholar paper={paper} />,
    openReview: <OpenReview paper={paper} />,
    crossRef: <CrossRef paper={paper} />,
    paperShelf: <PaperShelf paper={paper} />,
  };

  return (
    <View style={{height: '100%'}}>
      <PreferencesScreen
        padItem={false}
        sections={[
          {
            id: 'general',
            title: '',
            items: () => [
              {
                id: 'title',
                kind: ItemKind.Text,
                title: 'Title',
                subTitle: paper?.title,
                numberOfLines: 2,
                clickToCopy: true,
              },
              {
                id: 'authors',
                kind: ItemKind.List,
                title: `Authors (${paper?.authors.length})`,
                subItems: () => paper?.authors ?
                  paper.authors.map((author, i) => ({
                    id: 'author_' + i,
                    kind: ItemKind.Text,
                    title: author.fullName,
                    clickToCopy: true,
                  })) : [],
              },
              {
                id: 'affiliations',
                kind: ItemKind.List,
                readonly: true,
                title: `Affiliations (${paper?.affiliations.length})`,
                subItems: () => paper?.affiliations.map((aff, i) => ({
                  id: 'affiliation_' + i,
                  kind: ItemKind.Text,
                  title: aff,
                  clickToCopy: true,
                })) || [],
                numberOfLines: 2,
                clickToCopy: true,
              },
              {
                id: 'venue',
                kind: ItemKind.Text,
                readonly: true,
                title: 'Venue / Publisher',
                subTitle: (paper?.venue +
                  (paper?.venueShort ? ` (${paper.venueShort})` : '')) || 'N/A',
                numberOfLines: 2,
                clickToCopy: true,
              },
              {
                id: 'year',
                kind: ItemKind.Text,
                title: 'Year',
                subTitle: paper?.year || 'N/A',
                inline: true,
                clickToCopy: true,
              },
              {
                id: 'size',
                kind: ItemKind.Input,
                editable: false,
                title: 'Size',
                subTitle: paper?.size?.toString(),
                inline: true,
                devOnly: true,
              },
            ] as PreferenceItemProps[],
          },
          {
            id: 'others',
            title: '',
            items: () => [
              {
                id: 'references',
                kind: ItemKind.Menu,
                onPress: async () => {
                  try {
                    const papers = await getReferencePapers(paper);
                    navigation.push('CollectionPreviewRoot', {
                      screen: 'CollectionPreview',
                      params: {collection: {
                        ...createNewCollection(),
                        name: `${paper?.title}`,
                        description: `${papers.length} references found`,
                        papers:
                          Object.fromEntries(papers.map((p) => [p.id, p])),
                        paperIds: papers.map((p) => p.id),
                      } as Collection}});
                  } catch (e) {
                    dialog('Error', 'Cannot retrieve references');
                  }
                },
                title: `References` + (
                  paper?.numReferences ?
                  ` (${paper?.numReferences?.toLocaleString()})` :
                  ''),
              },
              {
                id: 'citations',
                kind: ItemKind.Menu,
                // onPress: () => {},
                onPress: async () => {
                  try {
                    const papers = await getCitationPapers(paper);
                    navigation.push('CollectionPreviewRoot', {
                      screen: 'CollectionPreview',
                      params: {collection: {
                        ...createNewCollection(),
                        name: `Citations of ${paper?.title}`,
                        description: `${papers.length} citations found`,
                        papers:
                          Object.fromEntries(papers.map((p) => [p.id, p])),
                        paperIds: papers.map((p) => p.id),
                      } as Collection}});
                  } catch (e) {
                    dialog('Error', 'Cannot retrieve citations');
                  }
                },
                title: `Citations` + (
                  paper?.numCitations ?
                  ` (${paper?.numCitations?.toLocaleString()})` :
                  ''),
              },
            ],
          },
          {
            id: 'others',
            title: '',
            items: () => [
              {
                id: 'ids',
                kind: ItemKind.List,
                title: 'Identifiers',
                subItems: () => [
                  {
                    id: 'id',
                    kind: ItemKind.Text,
                    title: 'PaperShelf ID',
                    subTitle: paper?.id,
                    inline: true,
                    clickToCopy: true,
                  },
                  {
                    id: 'arxiv_id',
                    kind: ItemKind.Text,
                    title: 'ArXiv ID',
                    subTitle: paper?.ids.arxiv,
                    inline: true,
                    clickToCopy: true,
                  },
                  {
                    id: 'semantic_scholar_id',
                    kind: ItemKind.Text,
                    title: 'Semantic Scholar ID',
                    subTitle: paper?.ids.semanticScholar,
                    inline: true,
                    clickToCopy: true,
                  },
                  {
                    id: 'doi',
                    kind: ItemKind.Text,
                    title: 'DOI',
                    subTitle: paper?.ids.doi,
                    inline: true,
                    clickToCopy: true,
                  },
                  {
                    id: 'mag',
                    kind: ItemKind.Text,
                    title: 'MAG',
                    subTitle: paper?.ids.mag,
                    inline: true,
                    clickToCopy: true,
                  },
                  {
                    id: 'dblp',
                    kind: ItemKind.Text,
                    title: 'DBLP',
                    subTitle: paper?.ids.dblp,
                    inline: true,
                    clickToCopy: true,
                  },
                ],
              },
              {
                id: 'urls',
                kind: ItemKind.List,
                title: `URLs (${paper?.urls.length})`,
                hidden: paper?.urls.length === 0,
                subItems: () =>
                  paper?.urls.map(({url, desc}) => ({
                    kind: ItemKind.Text,
                    id: url,
                    subTitle: url,
                    title: desc,
                    onPress: () => Linking.openURL(url),
                  })) || [],
              },
              {
                id: 'pdf',
                kind: ItemKind.Input,
                title: 'PDF',
                value: paper?.pdfUrl,
                readonly: !isPaperInLibrary(paper.id),
                inline: true,
                onChange: async (value) => {
                  await savePaperAndSync({
                    ...paper,
                    pdfUrl: (value || '') as string,
                  });
                },
              },
              {
                id: 'html',
                kind: ItemKind.Input,
                title: 'HTML',
                value: paper?.htmlUrl,
                readonly: true,
                inline: true,
                devOnly: true,
              },
            ],
          },
          {
            id: 'dates',
            title: '',
            items: () => [
              {
                id: 'date_added',
                kind: ItemKind.Input,
                title: 'Date Added',
                value: paper?.dateAdded ?
                  moment(paper?.dateAdded).calendar() : 'Never',
                readonly: true,
                inline: true,
              },
              {
                id: 'date_opened',
                kind: ItemKind.Input,
                title: 'Last Opened',
                value: paper?.dateOpened ?
                  moment(paper?.dateOpened).calendar() : 'Never',
                readonly: true,
                inline: true,
              },
              {
                id: 'date_modified',
                kind: ItemKind.Input,
                title: 'Last Modified',
                value: paper?.dateModified ?
                  moment(paper?.dateModified).calendar() : 'Never',
                readonly: true,
                inline: true,
              },
              // {
              //   id: 'date_fetched',
              //   kind: ItemKind.Input,
              //   title: 'Last Metadata Fetched',
              //   value: paper?.dateFetched ?
              //     moment(paper?.dateFetched).calendar() : 'Never',
              //   readonly: true,
              //   inline: true,
              // },
            ],
          },
          {
            id: 'actions',
            title: '',
            items: () => [
              {
                id: 'fetch_paper',
                kind: ItemKind.Button,
                title: 'Refresh',
                icon: isFetching[paper.id] ? ActivityIndicator : IconRefresh,
                subTitle: (isFetching[paper.id] && fetchingProgressText) ?
                  fetchingProgressText :
                    'Last Updated: ' + (
                      Object.entries(paper?.dateFetched || {}).length ? moment(
                          Math.max(...Object.values(paper?.dateFetched || {})),
                      ).calendar() : 'Never'),
                disabled: isFetching[paper.id],
                onPress: async () => {
                  if (!paper) return;
                  await fetchPaper(
                      paper, undefined, true,
                      (_, msg) => setFetchingProgressText(msg),
                      (p: Paper) => {
                        setPaper(p);
                      });
                },
              },
              {
                id: 'sources',
                kind: ItemKind.List,
                title: `Sources (${Sources.filter((s) => paper.sources[s.key] &&
                  !paper.sources[s.key].error).length})`,
                subItems: () =>
                  Sources.map((s) => {
                    const itemColor = paper.sources[s.key] ? (
                      paper.sources[s.key].error ?
                      theme.colors.error :
                      theme.colors.success) : theme.colors.black;
                    return {
                      id: s.name,
                      kind: ItemKind.Button,
                      title: s.name,
                      color: itemColor,
                      // route: s.name,
                      onPress: () => {
                        showModal(() => sourceViews[s.key], {
                          title: s.name,
                          height: 600,
                          width: 600,
                        });
                      },
                      leftButton: {
                        icon: isLoading[s.key] ?
                          ActivityIndicator : IconRefresh,
                        onPress: async () => {
                          setIsLoading({...isLoading, [s.key]: true});
                          await fetchPaper(
                              paper, [s.key], true, undefined, setPaper);
                          setIsLoading({...isLoading, [s.key]: false});
                        },
                      },
                      icon: paper.sources[s.key] ?
                        (paper.sources[s.key].error ? IconError : IconSuccess) :
                        IconDownload,
                    };
                  }),
              },
            ],
          },
          {
            id: 'admin',
            title: '',
            adminOnly: true,
            items: () => [
              {
                id: 'pdfPath',
                kind: ItemKind.Input,
                title: 'PDF Path',
                value: paper?.pdfPath,
                readonly: true,
                adminOnly: true,
                numberOfLines: 10,
              },
            ],
          },
        ]}
      />
    </View>
  );
};

export default Metadata;
