import {Button, Text, useTheme} from '@rneui/themed';
import {Configuration, OpenAIApi} from 'openai';
import React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {FlatList, Pressable, View} from 'react-native';
import {GiftedChat, IMessage} from 'react-native-gifted-chat';
import {useRecoilValue} from 'recoil';

import {isLoggedIn} from '../../common/api/utils';
import HeaderMenu from '../../components/HeaderMenu';
import {Toolbar, ToolbarItem} from '../../components/ToolbarItem';
import {NavigationContext} from '../../context';
import {analytics} from '../../platform/firebase';
import {
  IconChecked, IconDelete, IconHelp, IconSwitch, IconUnchecked,
} from '../../platform/icons';
import {settingsState} from '../../recoil/atoms';
import {PaperInfoContext} from './ContextProvider';

const Users: Record<string, {_id: number, name: string}> = {
  user: {
    _id: 1,
    name: 'You',
  },
  localBot: {
    _id: 2,
    name: 'Local Bot',
    // avatar: 'https://placeimg.com/140/140/any',
  },
  gptBot: {
    _id: 3,
    name: 'GPT-3 Bot',
    // avatar: 'https://placeimg.com/140/140/any',
  },
};

/**
 * Chat Tab
 * @returns tab
 */
export function Chat(): JSX.Element {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(true);
  const [text, setText] = useState<string>('');
  const {paper} = useContext(PaperInfoContext);
  const {navigate, showModal} = useContext(NavigationContext);
  const settings = useRecoilValue(settingsState);
  const {theme} = useTheme();
  const [currentBot, setCurrentBot] = useState<string>('gptBot');

  useEffect(() => {
    // if (isAdmin) {
    //   setRemainingChatQuestions(100);
    //   return;
    // }
    // Api.Profile.getRemainingChatQuestions().then((res) => {
    //   setRemainingChatQuestions(res);
    // });
  }, []);

  /**
   * @param message - message to append to chat
   */
  async function appendMessage(message: Partial<IMessage>) {
    setMessages((previousMessages) => GiftedChat.append(previousMessages, [
      {
        ...message,
        _id: previousMessages.length + 1,
      } as IMessage,
    ]));
  }

  const openEndedQuickReplies = [
    'What is the main contribution of this paper?',
    'Summarize this paper in three short sentences.',
    'What are some future directions for this paper?',
    'What is the research question or problem that the paper is addressing?',
    'What is the background or context for the research question or problem?',
    'What is the main hypothesis or argument of the paper?',
    'What are the key findings or results of the study?',
    'What are the implications or significance of the findings?',
    // eslint-disable-next-line max-len
    'What are the limitations or weaknesses of the study, and how might they be addressed in future research?',
    // eslint-disable-next-line max-len
    'Are there any ethical or social implications of the research that need to be considered?',
  ];

  const quickReplies = [{
    title: 'When and where was this paper published?',
    value: 'venue_year',
  }, {
    title: 'TL;DR?',
    value: 'tldr',
  }, {
    title: 'Show me URLs related to this paper.',
    value: 'urls',
  }, {
    title: 'Find me reviews from OpenReview.',
    value: 'openreview',
  }, {
    title: 'Show the paper content in text format.',
    value: 'content',
  }];

  /**
   * get quick replies that can be handled locally
   */
  function getQuickReplies(k = 3) {
    const n = quickReplies.length;
    const selected: {title: string, value: string}[] = [];

    for (let i = n - 1; i >= n - k; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [quickReplies[i], quickReplies[j]] = [quickReplies[j], quickReplies[i]];
      selected.push(quickReplies[i]);
    }

    selected.push({
      title: 'What else can I ask?',
      value: 'other_questions',
    });

    return selected;
  }

  /**
   * get quick replies that can be handled locally
   */
  function getOpenEndedQuickReplies(k = 3) {
    const arr = openEndedQuickReplies;
    const n = arr.length;
    const selected: {title: string, value: string}[] = [];

    for (let i = n - 1; i >= n - k; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
      selected.push({title: arr[i], value: ''});
    }

    selected.push({
      title: 'What else can I ask?',
      value: 'other_questions',
    });

    return selected;
  }

  /**
   * show suggested questions that can be handled locally
   */
  function showSuggestions() {
    const data = currentBot === 'gptBot' ?
      openEndedQuickReplies.map((title) => ({title, value: ''})) : quickReplies;
    showModal((close) => (<View>
      <FlatList data={data} renderItem={({item}) => (
        <Pressable onPress={() => {
          onSend(item.title, item.value);
          close();
        }} style={{
          borderBottomWidth: 1,
          borderColor: theme.colors.border,
        }}>
          {({pressed}) => <Text style={{
            paddingHorizontal: 16, paddingVertical: 12,
            fontSize: 16,
            backgroundColor: pressed ?
              theme.colors.backgroundHighlight :
              theme.colors.white}}>{item.title}</Text>}
        </Pressable>)} />
    </View>), {
      title: 'Suggested Questions',
      height: 400,
    });
  }

  /**
   * Get response from PaperShelf server
   */
  // async function getServerResponse(prompt: string) {
  //   const endpoint = 'https://papershelf-node.azurewebsites.net/api';
  //   const res = await fetch(
  //       `${endpoint}/GenerateResponse?` +
  //       (new URLSearchParams({
  //         id: paper.id,
  //         prompt,
  //       })));
  //   const data = await res.json();
  //   setMessages((previousMessages) => GiftedChat.append(previousMessages, [{
  //     _id: previousMessages.length + 1,
  //     text: (data.response as string).trim(),
  //     quickReplies: {
  //       type: 'radio',
  //       keepIt: false,
  //       values: (data.quickReplies || []).map((s: string) => ({
  //         title: s,
  //       })),
  //     },
  //     createdAt: new Date(),
  //     user: Users[currentBot],
  //   }]));
  //   if (prompt !== '' && !isAdmin) {
  //     await Api.Profile.decreaseRemainingChatQuestions();
  //     setRemainingChatQuestions(
  //         await Api.Profile.getRemainingChatQuestions());
  //   }
  // }

  /**
   * change bot
   * @param bot - bot to use
   */
  useEffect(() => {
    appendMessage({
      text: `Current Bot: ${Users[currentBot].name}`,
      system: true,
    });
    getResponse(currentBot, '', 'start');
  }, [currentBot]);

  /**
   * get GPT Bot response
   * @param prompt - prompt to send to chatbot
   * @param value - value
   */
  async function getGPTBotResponse(prompt: string, value = '') {
    const _getResponse = (text: string) => ({
      text,
      quickReplies: {
        type: 'radio',
        keepIt: false,
        values: getOpenEndedQuickReplies(),
      },
      user: Users['gptBot'],
    } as IMessage);
    if (value === 'set_up_api_key') {
      navigate('Preferences', {screen: 'LinkedAccount'});
      appendMessage(_getResponse(
          `If the API key is set up correctly, you can start asking questions.`,
      ));
      return;
    }

    if (value === 'use_local_bot') {
      setCurrentBot('localBot');
      return;
    }

    if (value === 'other_questions') {
      showSuggestions();
      return;
    }

    if (value === 'start') {
      appendMessage({
        // eslint-disable-next-line max-len
        text: `Hi, I'm GPT-3 Bot, powered by OpenAI.`,
        user: Users['gptBot'],
      });
      appendMessage(_getResponse(
          // eslint-disable-next-line max-len
          `We are looking into this paper "${paper.title}" by ${paper.authorFull}. How can I help you?`,
      ));
      return;
    }

    if (!settings.openAIKey) {
      appendMessage({
        // eslint-disable-next-line max-len
        text: `Before I can handle your request, please set up your OpenAI API key in Preferences. Your API key will only be stored locally and used to send request from your device. An API key can be created here: https://platform.openai.com/account/api-keys`,
        user: Users['gptBot'],
        quickReplies: {
          type: 'radio',
          keepIt: false,
          values: [
            {
              title: 'Go to Preferences',
              value: 'set_up_api_key',
            },
            {
              title: 'Switch to Local Bot',
              value: 'use_local_bot',
            },
          ],
        },
      });
    } else {
      setIsTyping(true);
      const configuration = new Configuration({
        apiKey: settings.openAIKey,
      });
      const openai = new OpenAIApi(configuration);
      // eslint-disable-next-line max-len
      const apiPrompt = `The paper "${paper.title}" was published in ${paper.year} by ${paper.authors.map((a) => a.fullName).join(', ')} in ${paper.venue}.\n\nAbstract: ${paper.abstract}\n\nAnswer the question with only information provided. If there is not enough evidence, reply "more"\n\n${prompt}`;
      const completion = await openai.createCompletion({
        model: 'text-davinci-003',
        // model: 'text-curie-001',
        prompt: apiPrompt,
        temperature: 0.1,
        top_p: 1,
        max_tokens: 300,
      });
      appendMessage(_getResponse(
        completion.data.choices[0].text?.trim() as string));
      // appendMessage(_getResponse('Please wait...'));
      setIsTyping(false);
    }
  }

  /**
   * get response from chatbot
   * if value is presented, the question is handled locally
   * if remainingChatQuestions exceeds a daily quota, it returns suggested
   * questions that can be handled locally
   * if not, send the question to LLM
   * @param prompt - prompt to send to chatbot
   */
  async function getResponse(currentBot: string, prompt: string, value = '') {
    if (currentBot === 'gptBot') {
      return getGPTBotResponse(prompt, value);
    }
    if (value) {
      const _getResponse = (text: string) => ({
        text,
        quickReplies: {
          type: 'radio',
          keepIt: false,
          values: getQuickReplies(),
        },
        user: Users['localBot'],
      } as IMessage);

      if (value === 'start') {
        appendMessage({
          // eslint-disable-next-line max-len
          text: 'Hi! I am a local chatbot. I have limited capabilities, but I can help you with some basic questions about this paper.',
          user: Users['localBot'],
        });
        appendMessage(_getResponse(
            // eslint-disable-next-line max-len
            `We are looking into this paper "${paper.title}" by ${paper.authorFull}. How can I help you?`,
        ));
      } else if (value === 'venue_year') {
        appendMessage(_getResponse(`This paper was published` +
            (paper.year ? ` in ${paper.year}` : '') +
            (paper.venue ? ` at ${paper.venue}` : '')));
      } else if (value === 'other_questions') {
        showSuggestions();
      } else if (value === 'tldr') {
        appendMessage(_getResponse(paper.tldr || 'No TL;DR available.'));
      } else if (value === 'urls') {
        appendMessage(_getResponse(
            'Here are some URLs related to this paper:\n' +
            paper.urls.map((u) => `${u.desc}: ${u.url}`).join('\n')));
      } else if (value === 'openreview') {
        appendMessage(_getResponse('No reviews found.'));
      } else if (value === 'content') {
        appendMessage(_getResponse(
            paper.textContent || 'No content available.'));
      } else {
        appendMessage(_getResponse(
            // eslint-disable-next-line max-len
            'Sorry, I cannot provide an answer for this request. Please switch to GPT bot for more advanced or open-ended questions. If you want to continue, choose one from the suggested list.'));
      }
      return;
    }
  }

  const onSend = useCallback((text: string, value = '') => {
    analytics?.logEvent('chatbot_send_message', {text});
    setMessages(
        (previousMessages) => GiftedChat.append(previousMessages, [{
          text,
          _id: previousMessages.length + 1,
          createdAt: new Date(),
          user: Users['user'],
        }]));
    getResponse(currentBot, text, value);
  }, [currentBot]);

  return isLoggedIn() ? <View style={{
    flexDirection: 'column', height: '100%',
    backgroundColor: theme.colors.white,
  }}>
    <Toolbar borderBottom={true}>
      <HeaderMenu headerMenuItems={[{
        title: 'Local Bot',
        onPress: () => {
          setCurrentBot('localBot');
        },
        icon: currentBot === 'localBot' ? IconChecked : IconUnchecked,
      }, {
        title: 'GPT Bot',
        onPress: () => {
          setCurrentBot('gptBot');
        },
        icon: currentBot === 'gptBot' ? IconChecked : IconUnchecked,
      }]} content={<View style={{paddingHorizontal: 8, flexDirection: 'row'}}>
        <IconSwitch />
      </View>} />
      <ToolbarItem title={Users[currentBot].name} />
      {/* <ToolbarItem
        title={'Remaining questions: ' + remainingChatQuestions} /> */}
      <View style={{flexGrow: 1}} />
      <ToolbarItem title="Clear" icon={IconDelete} onPress={() => {
        setMessages([]);
        appendMessage({
          text: `Current Bot: ${Users[currentBot].name}`,
          system: true,
        });
        getResponse(currentBot, '', 'start');
      }} />
    </Toolbar>
    <GiftedChat
      // messagesContainerStyle={{flex: 1}}
      wrapInSafeArea={false}
      messages={messages}
      onSend={(messages) => onSend(messages[0].text)}
      user={{
        _id: 1,
      }}
      isTyping={isTyping}
      onQuickReply={(replies) => {
        const reply = replies[0];
        onSend(reply.title, reply.value);
      }}
      text={text}
      onInputTextChanged={(text) => {
        setText(text);
      }}
      renderActions={(props) => <View>
        <Button style={{paddingBottom: 8, paddingLeft: 4}}
          type="clear" icon={<IconHelp />} onPress={() => {
            showSuggestions();
          }} />
      </View>}
    /></View> : <View style={{
      alignItems: 'center', flexGrow: 1, justifyContent: 'center'}}
    >
      <Text>You must log in to use this feature</Text>
      <Button
        type="clear" title="Login"
        titleStyle={{fontSize: 16, marginLeft: 8}}
        onPress={() => {
          navigate('Preferences', {screen: 'Account'});
        }} />
    </View>;
}
