import {atom} from 'recoil';

import {ModalOptionsType} from '../../components/Modal';

export const isModalVisibleState = atom<boolean>({
  key: 'IsModalVisible',
  default: false,
});

export const modalContentState = atom<JSX.Element>({
  key: 'ModalContent',
  default: undefined,
});

export const modalOptionsState = atom<ModalOptionsType | undefined>({
  key: 'ModalOptions',
  default: undefined,
});

export const isModalScreenVisibleState = atom<boolean>({
  key: 'IsModalScreenVisible',
  default: false,
});

export const modalScreenContentState = atom<JSX.Element>({
  key: 'ModalScreenContent',
  default: undefined,
});

export const modalScreenOptionsState = atom<ModalOptionsType | undefined>({
  key: 'ModalScreenOptions',
  default: undefined,
});
