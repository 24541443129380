import {Alert, AlertButton, AlertOptions} from 'react-native';

import {isIOS, isWeb} from '.';

export const dialog = isWeb ?
  (
      title: string,
      message?: string,
      buttons?: AlertButton[],
      _options?: AlertOptions,
  ) => {
    const result = window.confirm(
        [title, message].filter(Boolean).join('\n'),
    );

    if (result) {
      const confirmOption = buttons?.find(({style}) => style !== 'cancel');
      confirmOption?.onPress && confirmOption.onPress();
    } else {
      const cancelOption = buttons?.find(({style}) => style === 'cancel');
      cancelOption?.onPress && cancelOption.onPress();
    }
  } :
  Alert.alert;

export const prompt = (
    title: string,
    subTitle: string,
    value: string,
    onSubmit: (val?: string) => void,
    onCancel?: () => void,
) => {
  if (isIOS) {
    return Alert.prompt(
          typeof title === 'string' ? title : '',
          subTitle,
          [
            {
              text: 'Cancel',
              style: 'cancel',
              onPress: (_val) => onCancel && onCancel(),
            },
            {
              text: 'Change',
              onPress: (text) => {
                onSubmit(text);
              },
            },
          ],
          'plain-text',
          value,
    );
  }
};
