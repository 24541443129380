import {db} from '../../platform/firebase';
import {
  localStorageGetItem,
  localStorageGetKeys,
  localStorageRemoveItem,
  localStorageSetItem,
} from '../../platform/localStorage';
import Tag, {createNewTag, getTagJSON, TagProps} from '../tag';
import {uid, updateLastSynced} from './utils';

const getDefaultTags = async (): Promise<Record<string, TagProps>> => {
  if (!db) return {};
  try {
    const data = await db?.ref('default/tags').once('value');
    return (data.val() || {}) as Record<string, TagProps>;
  } catch (e) {
    return {};
  }
};

/**
 * Save tag in the local storage and on the server.
 * @param t - a tag object.
 * @returns a tag object
 */
const save = async (t: Tag): Promise<Tag> => {
  // Save to local storage
  await localStorageSetItem(`tag:${t.key}`, JSON.stringify(getTagJSON(t)));

  // Sync to remote server
  if (uid()) {
    await db?.ref(`users/${uid()}/tags/${t.key}`).set({
      ...getTagJSON(t),
    });

    await updateLastSynced();
  }
  return t;
};

/**
 * Remove all tags locally and on the server.
 */
const removeAll = async (): Promise<void> => {
  const keys = await localStorageGetKeys();
  await Promise.all(
      keys
          .filter((key) => key.startsWith('tag:'))
          .map((key) => localStorageRemoveItem(key)),
  );
  if (uid()) {
    await db?.ref(`users/${uid()}/tags`).remove();
  }
  await updateLastSynced();
};

const TagApi = {
  save,
  load: async (tid: string): Promise<Tag | null> => {
    const json = await localStorageGetItem(`tag:${tid}`);
    return json ?
      createNewTag({
        ...JSON.parse(json),
        key: tid,
      }) :
      null;
  },
  syncFromServer: async (): Promise<void> => {
    const snapshot = await db?.ref(`users/${uid()}/tags`).once('value');
    const data = snapshot?.val() || (await getDefaultTags());
    for (const [key, d] of Object.entries(data)) {
      await localStorageSetItem(`tag:${key}`, JSON.stringify(d));
    }
    // remove deleted tags
    const keys = await localStorageGetKeys();
    keys
        .filter((key) => key.startsWith('tag:'))
        .forEach((key) => {
          if (!Object.keys(data).includes(key.slice(4))) {
            localStorageRemoveItem(key);
          }
        });
  },
  syncToServer: async (allTags: Record<string, Tag>): Promise<void> => {
    await Promise.all(
        Object.entries(allTags).map(([, t]) =>
          localStorageSetItem(`tag:${t.key}`, JSON.stringify(getTagJSON(t))),
        ),
    );
    // Sync to remote server
    if (uid()) {
      await db?.ref(`users/${uid()}/tags`).set(allTags);
      await updateLastSynced();
    }
  },
  remove: async (t: Tag): Promise<void> => {
    await localStorageRemoveItem(`tag:${t.key}`);
    if (uid()) {
      await db?.ref(`users/${uid()}/tags/${t.key}`).remove();
    }
    await updateLastSynced();
  },
  removeAll,
  getDefaultTags,
};

export default TagApi;
