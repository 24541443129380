import {useTheme} from '@rneui/themed';
import React from 'react';
import {TextInput as _TextInput} from 'react-native';
import {TextInputProps} from 'react-native';

const TextInput = (props: TextInputProps) => {
  const {theme} = useTheme();
  return <_TextInput {...props} style={[props.style, {
    color: theme.colors.black,
  }]} />;
};

export default TextInput;
