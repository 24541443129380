import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import React, {useContext} from 'react';
import {
  Linking,
} from 'react-native';
import {useRecoilState, useRecoilValue} from 'recoil';

import {AppContext} from '../context';
import {MainStackParamList} from '../Main';
import {
  IconConfig,
  IconDownload,
  IconNote,
  IconNoteOff,
  IconOpenUrl,
  IconShare,
  IconShareFile,
} from '../platform/icons';
import {
  currentPaperState, isDownloadingPaperState, settingsState,
} from '../recoil/atoms';
import {isPhoneState, isWebState, layoutState} from '../recoil/selectors';
import ActivityIndicator from './ActivityIndicator';
import HeaderMenu, {HeaderMenuItemProps} from './HeaderMenu';

const PdfHeaderMenu = () => {
  const {download, share, sharePdf} = useContext(AppContext);
  const isWeb = useRecoilValue(isWebState);
  const paper = useRecoilValue(currentPaperState);
  const [settings, setSettings] = useRecoilState(settingsState);
  const isPhone = useRecoilValue(isPhoneState);
  const layout = useRecoilValue(layoutState);
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();
  const isDownloading = useRecoilValue(isDownloadingPaperState);

  const menuItems: HeaderMenuItemProps[] = [];

  if (isPhone) {
    menuItems.push({
      icon: IconOpenUrl,
      title: 'Open in Browser',
      disabled: !paper?.pdfUrl,
      onPress: () => {
        if (!paper?.pdfUrl) return;
        Linking.openURL(paper?.pdfUrl);
      },
    });
  }

  if (layout === 'one-column') {
    menuItems.push({
      icon: IconNote,
      title: 'Show Notes',
      onPress: () => {
        navigation.navigate('Notes');
      },
    });
  } else {
    menuItems.push({
      icon: settings.showNotes ? IconNoteOff : IconNote,
      title: settings.showNotes ? 'Hide Notes' : 'Show Notes',
      onPress: () => {
        setSettings({...settings, showNotes: !settings.showNotes});
        // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      },
    });
  }

  if (!isWeb) {
    if (paper && !paper?.pdfPath) {
      menuItems.push({
        icon: isDownloading[paper.id] ? ActivityIndicator : IconDownload,
        disabled: !!isDownloading[paper.id],
        title: 'Download',
        onPress: async () => {
          if (!paper) return;
          await download(paper);
        },
      });
    }

    menuItems.push({
      icon: IconShare,
      title: 'Share Link',
      onPress: async () => {
        await new Promise((r) => setTimeout(r, 100));
        await share();
        // TODO: close header menu before share dialog appears
      },
    });

    if (paper?.pdfPath) {
      menuItems.push({
        icon: IconShareFile,
        title: 'Share PDF',
        onPress: async () => {
          await new Promise((r) => setTimeout(r, 100));
          await sharePdf(paper);
          // TODO: close header menu before share dialog appears
        },
      });
    }

    menuItems.push({
      icon: IconConfig,
      title: 'Settings',
      onPress: () => {
        navigation.navigate('Preferences', {screen: 'PdfViewer'});
      },
    });
  }

  return <HeaderMenu headerMenuItems={menuItems} />;
};

export default PdfHeaderMenu;
