import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {uniqBy} from 'lodash';
import {appendTags, removeTag} from 'paper-fetch';
import {SourceKey, SourcePaper} from 'paper-fetch';
import React, {useContext, useEffect, useState} from 'react';
import {
  View,
} from 'react-native';
import {useRecoilState, useRecoilValue} from 'recoil';

import Api from '../../common/api';
import {isPaperInCollection} from '../../common/collection';
import Paper from '../../common/paper';
import {createNewTag, tagCompareFn} from '../../common/tag';
import {
  ItemKind,
  PreferenceItemProps,
  PreferencesScreen,
} from '../../components/Preferences';
import {AppContext, NavigationContext} from '../../context';
import {MainStackParamList} from '../../Main';
import {
  IconChat,
  IconCollection,
  IconHome,
  IconInfo,
  IconManageCollection,
  IconTag,
} from '../../platform/icons';
import {
  allCollectionsState,
  allTagsState,
  currentPaperState,
  isFetchingPaperState,
} from '../../recoil/atoms';
import {realmState} from '../../recoil/selectors';
import {Chat} from './Chat';
import {PaperInfoContext} from './ContextProvider';
import Main from './Main';
import Metadata from './Metadata';

// const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

const Collections = () => {
  const {
    removePaperFromCollection,
    addPaperToCollection,
  } = useContext(AppContext);
  const allCollections = useRecoilValue(allCollectionsState);
  const navigation =
    useNavigation<StackNavigationProp<MainStackParamList>>();
  const {savePaperAndSync, isPaperInLibrary} = useContext(AppContext);
  const {paper, setPaper} = useContext(PaperInfoContext);
  const allTags = useRecoilValue(allTagsState);

  return (
    <PreferencesScreen
      padItem={false}
      sections={[
        {
          id: 'manage',
          items: () => [
            {
              id: 'collections',
              kind: ItemKind.List,
              title: 'Collections',
              defaultShowSubItems: true,
              subItems: () => [
                ...(allCollections.length > 0 ? [...allCollections]
                    .filter((c) => !c.isPublic && !c.publicCollectionKey)
                    .sort((a, b) =>
                      (a.order - b.order) || (a.name.localeCompare(b.name)))
                    .map((c) => ({
                      id: c.name,
                      kind: ItemKind.CheckBox,
                      title: c.name,
                      hidden: c.isPublic || !!c.publicCollectionKey,
                      value: paper && isPaperInCollection(paper.id, c),
                      onPress: () => {
                        if (paper) {
                          if (isPaperInCollection(paper.id, c)) {
                            removePaperFromCollection(paper.id, c);
                          } else {
                            addPaperToCollection(paper, c);
                          }
                        }
                      },
                    } as PreferenceItemProps)) : []
                ),
              ],
            },
            {
              kind: ItemKind.Menu,
              id: 'manage_collections',
              title: 'Manage Collections',
              icon: IconManageCollection,
              onPress: () => {
                navigation.navigate('Preferences', {screen: 'Collections'});
              },
            },
          ],
        }, {
          id: 'tags',
          items: () => [{
            id: 'tags',
            kind: ItemKind.List,
            title: 'Tags',
            defaultShowSubItems: true,
            subItems: () => [
              ...uniqBy(
                  [
                    ...Object.values(allTags)
                        .filter((t) => !t.key.includes(':')).sort(tagCompareFn),
                    ...(paper?.customTags
                        .map((key) => createNewTag({key})) || []),
                  ],
                  (t) => t.key,
              ).map(
                  (tag) =>
                    ({
                      id: tag.key,
                      kind: ItemKind.CheckBox,
                      title: tag.key,
                      value: !!(paper && paper.id) &&
                      paper.tags.includes(tag.key),
                      disabled: tag.key.includes(':') ||
                        !isPaperInLibrary(paper.id),
                      onChange: (_val) => {
                        if (!paper) return;
                        const newPaper: Paper = {...paper};
                        if (paper && paper.id) {
                          if (paper.customTags.includes(tag.key)) {
                            newPaper.customTags = removeTag(
                                newPaper.customTags || [], tag.key);
                          } else {
                            newPaper.customTags = appendTags(
                                newPaper.customTags, [tag.key]);
                          }
                          savePaperAndSync(newPaper);
                          setPaper(newPaper);
                        }
                      },
                    } as PreferenceItemProps),
              ),
            ],
          }, {
            kind: ItemKind.Menu,
            id: 'manage_tags',
            title: 'Manage Tags',
            icon: IconTag,
            onPress: () => {
              navigation.navigate('Preferences', {screen: 'Tags'});
            },
          }],
        }]}
    />
  );
};

export const PaperInfoComponent = ({
  paper,
  setPaper,
  onViewPaper,
}: {
  paper: Paper,
  setPaper: (p: Paper) => void,
  onViewPaper: () => void,
}) => {
  const {fetchPaper} = useContext(AppContext);
  const realm = useRecoilValue(realmState);
  const isFetching = useRecoilValue(isFetchingPaperState);

  useEffect(() => {
    const onPaperChanged = async () => {
      if (Object.entries(paper.sources).length === 0) {
        const sources = await Api.Paper.local.loadSources(paper.id, realm);
        if (Object.values(sources).some((s) => !!s)) {
          setPaper({
            ...paper,
            sources: {
              ...Object.fromEntries(
                  Object.entries(sources).filter(([_, v]) => v)),
            } as Record<SourceKey, SourcePaper>,
          });
          return;
        }
      }
      if (!isFetching[paper.id]) {
        await fetchPaper(paper, undefined, false, setPaper, setPaper);
      }
    };

    onPaperChanged();
  }, [paper]);

  return <PaperInfoContext.Provider value={{
    paper, setPaper, onViewPaper,
  }}>
    <View style={{flex: 1, borderWidth: 0}}>
      <Tab.Navigator screenOptions={{
        headerShown: false,
        headerBackgroundContainerStyle: {
          borderWidth: 1,
        },
        headerTitleContainerStyle: {
          flex: 1000,
          padding: 0,
        },
        tabBarItemStyle: {
          height: 48,
        },
        tabBarStyle: {
          height: 48,
        },
      }}>
        <Tab.Screen
          key="PaperInfoMain"
          name="Overview"
          component={Main}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconHome color={color} size={size} />
            ),
          }}
        />
        <Tab.Screen
          key="Metadata"
          name="Metadata"
          component={Metadata}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconInfo color={color} size={size} />
            ),
          }}
        />
        <Tab.Screen
          key="Collections"
          name="Organize"
          component={Collections}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconCollection color={color} size={size} />
            ),
          }}
        />
        <Tab.Screen
          key="Chat"
          name="Chat"
          component={Chat}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconChat color={color} size={size} />
            ),
          }}
        />
        {/* <Tab.Screen
          key="Posts"
          name="Posts"
          component={Tags}
          options={{
            tabBarIcon: ({color, size}) => (
              <IconTag color={color} size={size} />
            ),
          }}
        /> */}
      </Tab.Navigator>
    </View>
  </PaperInfoContext.Provider>;
};

/**
 * Wrapper of `PaperInfoComponent` to show paper details in the main navigation
 * @returns JSX.Element
 */
function PaperInfoScreen(): JSX.Element {
  const {isCurrentPaper} = useContext(AppContext);
  const [currentPaper, setCurrentPaper] = useRecoilState(currentPaperState);
  const [paper, setPaper] = useState<Paper>();

  const route = useRoute<RouteProp<MainStackParamList, 'PaperInfo'>>();
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();
  const {navigate} = useContext(NavigationContext);

  useEffect(() => {
    if (!route.params?.paper) return;
    setPaper(route.params?.paper);
    navigation.setOptions({
      title: route.params?.paper?.title,
    });
  }, [navigation, route.params?.paper]);

  return (paper || currentPaper) ?
    <PaperInfoComponent
      paper={(paper || currentPaper) as Paper}
      setPaper={paper ? setPaper : (p: Paper) => {
        if (isCurrentPaper(p)) setCurrentPaper(p);
      }}
      onViewPaper={() => {
        navigate('PaperView');
      }} /> : <></>;
}

export default PaperInfoScreen;
