import {createDrawerNavigator} from '@react-navigation/drawer';
import {
  createNavigationContainerRef,
  NavigationContainer,
  NavigatorScreenParams,
  Theme,
} from '@react-navigation/native';
import {useTheme} from '@rneui/themed';
import * as Linking from 'expo-linking';
import React, {useContext, useEffect} from 'react';
import {Platform, View} from 'react-native';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import Paper, {createNewPaper} from './common/paper';
import CollectionDrawer from './components/CollectionDrawer';
import {ScreenContainer} from './components/ScreenContainer';
import {AppContext} from './context';
import {NavigationContextProvider} from './ContextProvider';
import {Main, MainStackParamList} from './Main';
import {dialog} from './platform/dialog';
import {analytics} from './platform/firebase';
import {
  currentPaperState,
} from './recoil/atoms';
import {isWebState, layoutState} from './recoil/selectors';

const Drawer = createDrawerNavigator<AppStackParamList>();

export type AppStackParamList = {
  Drawer: undefined;
  Main: NavigatorScreenParams<MainStackParamList>;
};

export const appNavigationRef =
    createNavigationContainerRef<AppStackParamList>();

const Navigation = ({
  modal,
  modalScreen,
  theme: navigationTheme,
}: {
  modal: JSX.Element;
  modalScreen: JSX.Element;
  theme: Theme;
}): JSX.Element => {
  const {theme} = useTheme();
  const routeNameRef = React.useRef<string>();
  const {fetchPaper} = useContext(AppContext);
  const setCurrentPaper = useSetRecoilState(currentPaperState);
  const isWeb = useRecoilValue(isWebState);
  const layout = useRecoilValue(layoutState);

  const handleUrlChanged = async (e: Linking.EventType) => {
    if (isWeb) return;
    if (!e.url) return;
    const paper = await fetchPaper(
        {
          ...createNewPaper(),
          pdfUrl: e.url.split('://', 2)[1].replace('https//', 'https://'),
        } as Paper,
    );
    if (paper.id) {
      setCurrentPaper(paper);
      appNavigationRef.navigate('Main', {
        screen: 'PaperInfo', params: {paper: undefined}});
    } else {
      if (Platform.OS !== 'web') {
        dialog(
            'Unidentifiable',
            'The paper cannot be identified. ' +
            'Either the link does not contain a valid paper or it is not ' +
            'supported.',
        );
      }
    }
  };

  useEffect(() => {
    const sub = Linking.addEventListener('url', handleUrlChanged);
    return () => sub.remove();
  }, []);

  return (
    <NavigationContainer
      ref={appNavigationRef}
      theme={navigationTheme}
      onReady={() => {
        routeNameRef.current =
          appNavigationRef.current?.getCurrentRoute()?.name;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName =
          appNavigationRef.current?.getCurrentRoute()?.name;

        if (!isWeb && previousRouteName !== currentRouteName) {
          await analytics?.logScreenView({
            screen_name: currentRouteName,
            screen_class: currentRouteName,
          });
        }
        routeNameRef.current = currentRouteName;
      }}
      linking={{
        prefixes: [],
        config: {
          screens: {
            Main: {
              screens: {
                PublicCollections: {
                  screens: {
                    Main: 'PublicCollections',
                  },
                },
                Preferences: {
                  screens: {
                    Main: 'Preferences/Main',
                    Account: 'Preferences/Account',
                  },
                },
              },
            },
          },
        },
      }}
      documentTitle={{
        formatter: (_options, _route) =>
          `PaperShelf`,
      }}
    >
      <NavigationContextProvider>
        {layout === 'three-column' ? (
        <ScreenContainer testID="main_container">
          {modalScreen}
          {modal}
          <Main />
        </ScreenContainer>
      ) : (
        <View style={{flex: 1}}>
          {modal}
          <Drawer.Navigator
            drawerContent={(props) => <CollectionDrawer {...props} />}
            screenOptions={{
              headerStyle: {backgroundColor: theme.colors?.white},
              headerTitleStyle: {color: theme.colors?.black},
              sceneContainerStyle: {backgroundColor: theme.colors?.white},
            }}
            initialRouteName="Main"
          >
            <Drawer.Screen
              name="Main"
              component={Main}
              options={{
                headerShown: false,
              }}
            />
          </Drawer.Navigator>
        </View>
      )}
      </NavigationContextProvider>
    </NavigationContainer>
  );
};

export default Navigation;
