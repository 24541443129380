import firebase from 'firebase';
require('firebase/firestore');

const firebaseConfig = __DEV__ ? {
  apiKey: 'AIzaSyCQBmkzcTEWYmhZq9BDHJEQzHjhKS5mgko',
  authDomain: 'paper-shelf-dev.firebaseapp.com',
  databaseURL: 'https://paper-shelf-dev-default-rtdb.firebaseio.com',
  projectId: 'paper-shelf-dev',
  storageBucket: 'paper-shelf-dev.appspot.com',
  messagingSenderId: '514733318007',
  appId: '1:514733318007:web:51ddcd8f35df1ea0564b72',
} : {
  apiKey: 'AIzaSyBwpP4KSpb7ADdfAKx3o1Mta-RO4NT6ho0',
  authDomain: 'paper-shelf.firebaseapp.com',
  databaseURL: 'https://paper-shelf-default-rtdb.firebaseio.com',
  projectId: 'paper-shelf',
  storageBucket: 'paper-shelf.appspot.com',
  messagingSenderId: '466907878500',
  appId: '1:466907878500:web:2dba227d81a7b3e656fb71',
  measurementId: 'G-7X2K30YBG3',
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.database();
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const analytics = undefined; // firebase.analytics();
export const functions = firebase.functions();

// _firestore.settings({
//   ignoreUndefinedProperties: true,
// });

// functions?.useEmulator('localhost', 5001);

// export const FacebookAuthProvider = _auth.FacebookAuthProvider;
// export const GoogleAuthProvider = _auth.GoogleAuthProvider;
