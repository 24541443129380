import React from 'react';

import Paper, {createNewPaper} from '../../common/paper';

export type PaperInfoContextType = {
  paper: Paper;
  setPaper: (paper: Paper) => void;
  onViewPaper: () => void;
}

export const PaperInfoContext = React.createContext<PaperInfoContextType>({
  paper: createNewPaper(),
  setPaper: (_p?: Paper) => {/**/},
  onViewPaper: () => {/**/},
});
