import {auth, db, firestore} from '../../platform/firebase';
import {uid, UnauthenticatedActionError} from './utils';

export type UserInfo = {
  name: string;
  affliation?: string;
  website?: string;
  remainingChatQuestions?: number;
};

/**
 * get user info
 * @param uid - user id
 * @returns UserInfo
 */
async function getUserInfo(): Promise<UserInfo> {
  if (!uid()) throw UnauthenticatedActionError;
  try {
    // const snapshot = await db?.ref('userDetails').child(uid).once('value');
    // return snapshot?.val() as UserInfo;
    const snapshot = await firestore?.collection('UserDetails')
        .doc(uid()).get();
    return snapshot?.data() as UserInfo;
  } catch (e) {
    return {
      name: 'Anonymous',
    } as UserInfo;
  }
}

/**
 * save user info
 * @param userInfo - user info
 */
async function saveUserInfo(userInfo: UserInfo): Promise<void> {
  if (!uid()) throw UnauthenticatedActionError;
  // await db?.ref('userDetails').child(uid()).set(userInfo);
  await firestore?.collection('UserDetails').doc(uid())
      .set(userInfo);
}

/** */
async function getRemainingChatQuestions() {
  if (!uid()) return Promise.resolve(0);
  const userInfo = await getUserInfo();
  if (userInfo.remainingChatQuestions === undefined) return 5;
  else return userInfo.remainingChatQuestions;
}

/** */
async function decreaseRemainingChatQuestions() {
  const userInfo = await getUserInfo();
  const remaining = (userInfo.remainingChatQuestions === undefined ? 5 - 1 :
    userInfo.remainingChatQuestions - 1);
  await saveUserInfo({
    ...userInfo,
    remainingChatQuestions: remaining,
  });
}

export default {
  // changeName: async (newName: string): Promise<void> => {
  //   const uid = auth?.currentUser?.uid;
  //   if (!uid) throw UnauthenticatedActionError;
  //   await db?.ref('userDetails').child(uid).child('name').set(newName);
  // },
  getUserInfo, saveUserInfo,
  isAdmin: async () => {
    if (!uid()) return Promise.resolve(false);
    const snapshot = await firestore?.collection('Roles').doc(uid()).get();
    const roles = snapshot?.data();
    return roles?.admin as boolean;
  },
  deleteAccount: async (): Promise<void> => {
    if (!uid()) throw UnauthenticatedActionError;
    const userId = uid();
    await db?.ref('userDetails').child(userId).remove();
    await db?.ref(`users/${userId}`).remove();
    await auth?.currentUser?.delete();
  },
  getRemainingChatQuestions,
  decreaseRemainingChatQuestions,
};
