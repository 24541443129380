import {DrawerNavigationProp} from '@react-navigation/drawer';
import {
  useNavigation,
} from '@react-navigation/native';
import {Text, useTheme} from '@rneui/themed';
import React, {useRef} from 'react';
import {
  TouchableOpacity,
  View,
} from 'react-native';
import {TextInput} from 'react-native-gesture-handler';
import {useRecoilValue} from 'recoil';

import {MainStackParamList} from '../Main';
import {
  IconBookshelf,
  IconClose,
  IconMenu,
  IconSearch,
  IconSearchWeb,
} from '../platform/icons';
import {currentCollectionState} from '../recoil/atoms';
import {layoutState} from '../recoil/selectors';
import ActivityIndicator from './ActivityIndicator';
import MainHeaderMenu from './MainHeaderMenu';
import {Toolbar, ToolbarItem} from './ToolbarItem';

type ListHeaderProps = {
  isSearchMode: boolean;
  onChangeText: (search: string) => void;
  onEndEditing: () => void;
  onSubmitEditing: () => void;
  onSearch: () => void;
  onClear: () => void;
  onCollectionClick: () => void;
  searchQuery: string;
  isLoading: boolean;
  onStartSearch: () => void;
  onOpenMenu: () => void;
};

const PaperListHeader = ({
  isSearchMode,
  onChangeText,
  onEndEditing,
  onSubmitEditing,
  onSearch,
  onClear,
  onCollectionClick,
  onStartSearch,
  searchQuery,
  isLoading,
}: ListHeaderProps) => {
  const navigation = useNavigation<DrawerNavigationProp<MainStackParamList>>();
  const searchRef = useRef<TextInput>(null);
  const layout = useRecoilValue(layoutState);
  const currentCollection = useRecoilValue(currentCollectionState);
  const {theme} = useTheme();
  return (
    <Toolbar borderBottom>
      {isSearchMode ? (
        <>
          <TextInput
            testID='search_input'
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style={{
              flex: 1,
              color: theme.colors.black,
              paddingHorizontal: 8,
              borderBottomColor: 'transparent'}}
            placeholderTextColor={theme.colors?.backgroundHighlight}
            underlineColorAndroid="transparent"
            placeholder="Paper title or URL"
            value={searchQuery}
            autoFocus={true}
            selectTextOnFocus={true}
            clearButtonMode="always"
            enablesReturnKeyAutomatically={true}
            keyboardType="web-search"
            onChangeText={onChangeText}
            onEndEditing={onEndEditing}
            onSubmitEditing={onSubmitEditing}
            returnKeyType="search"
            ref={searchRef}
          />
          {isLoading ? (
            <ToolbarItem icon={ActivityIndicator} />
          ) : (
            <ToolbarItem
              icon={() => <IconSearchWeb size={24} />}
              onPress={onSearch}
              testID="btn_search"
            />
          )}
          <ToolbarItem
            icon={IconClose}
            onPress={onClear}
            testID="btn_clear_search"
          />
        </>
      ) : (
        <>
          {layout === 'three-column' ? (
              <ToolbarItem icon={IconBookshelf} testID="btn_library" />
          ) : (
            <ToolbarItem
              icon={IconMenu}
              onPress={() => navigation.toggleDrawer()}
              testID="btn_toggle_collection_drawer"
            />
          )}
          <View style={{flex: 1}}>
            <TouchableOpacity
              onPress={onCollectionClick}
              style={{marginRight: 8}}
            >
              <Text
                style={{fontWeight: 'bold'}}
                testID="txt_current_collection"
                numberOfLines={1}
              >
                {currentCollection?.name || 'Library'}
              </Text>
            </TouchableOpacity>
          </View>
        </>
      )}

      {!isSearchMode && (
        <>
          {!currentCollection?.publicCollectionKey && <ToolbarItem
            icon={IconSearch}
            testID="btn_toggle_search"
            onPress={() => {
              if (searchRef.current) searchRef.current.focus();
              onStartSearch();
            }}
          />}
          <MainHeaderMenu />
          {/* <ToolbarItem
            icon={<IconMore />}
            testID="btn_more"
            onPress={() => {
              onOpenMenu();
            }}
          /> */}
        </>
      )}
    </Toolbar>
  );
};

export default PaperListHeader;
