import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Get a key's value in local storage
 * @param key - key
 * @returns
 */
export function localStorageGetItem(key: string): Promise<string | null> {
  return AsyncStorage.getItem(key);
}

/**
 * Update a key's value in local storage.
 * @param key - key
 * @param value - value
 */
export function localStorageSetItem(key: string, value: string): Promise<void> {
  return AsyncStorage.setItem(key, value);
}

/**
 * Get all keys in local storage.
 * @returns list of keys
 */
export function localStorageGetKeys(): Promise<readonly string[]> {
  return AsyncStorage.getAllKeys();
}

/**
 * Remove a key-value pair in local storage.
 * @param key - key
 */
export function localStorageRemoveItem(key: string): Promise<void> {
  return AsyncStorage.removeItem(key);
}

/**
 * Check if local storage was initialized.
 * @returns `true` if local storage was initialized.
 */
export async function checkLocalStorageInitalized(): Promise<boolean> {
  return (await localStorageGetItem('initialized')) != null;
}
