import {Text, useTheme} from '@rneui/themed';
import {
  Ar5ivPaper,
  ArxivPaper,
  CrossRefPaper,
  OpenReviewPaper,
  SemanticScholarPaper,
  SourceKey,
  SourcePaper,
  Sources,
} from 'paper-fetch';
import React from 'react';
import {View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';

import Paper from '../../common/paper';
import {
  ItemKind,
  PreferenceItemProps,
} from '../../components/Preferences';

const getItem = (
    p: SourcePaper,
    field: string,
    value?: string,
): PreferenceItemProps => ({
  id: field,
  title: field,
  kind: ItemKind.Text,
  value: value || ((p as Record<string, unknown>)[field] as string),
  inline: true,
  numberOfLines: 5,
});

const SourceView = ({paper, sourceKey, getItemsFn}: {
  paper: Paper,
  sourceKey: SourceKey,
  getItemsFn: (p: SourcePaper) => PreferenceItemProps[]
}) => {
  const source = Sources.find((src) => src.key === sourceKey);
  const {theme} = useTheme();

  return (
        paper ? (<View style={{flex: 1}}>
          <View style={{
            flexDirection: 'row',
            padding: 16,
            alignItems: 'center',
          }}>
            <View style={{flex: 1}}>
              <Text>List of fields from {source?.name}</Text>
              <Text>Website: {source?.url}</Text>
            </View>
          </View>
          <FlatList
            testID={`list_metadata_fields`}
            style={{flex: 1}}
            bounces={false}
            ListEmptyComponent={<Text style={{padding: 16}}>No data</Text>}
            data={[
              ...(paper.sources[sourceKey] && !paper.sources[sourceKey].error ?
              getItemsFn(paper.sources[sourceKey]).sort((a, b) => (
                (a.title || '') as string)
                  .localeCompare((b.title || '') as string)) :
              (paper.sources[sourceKey]?.error ?
                [getItem(paper.sources[sourceKey], 'error')] :
                [])),
            ]}
            renderItem={({item, index}) => <View
              style={{
                flexDirection: 'row',
                borderColor: theme.colors.border,
                borderBottomWidth: 1,
                borderTopWidth: index === 0 ? 1 : 0,
              }}
            >
              <Text
                style={{
                  padding: 8,
                  flex: 1,
                  maxWidth: 120,
                  backgroundColor: theme.colors.backgroundHighlight}}>
                {item.title}
              </Text>
              <Text
                testID={'field_' + item.id}
                numberOfLines={10}
                style={{
                  flex: 2,
                  padding: 8,
                }}>{item.value}</Text>
            </View>}
          />
        </View>) : <></>
  );
};

export type SourceDetailsProps = {
  paper: Paper;
}

export const ArXiv = ({paper}: SourceDetailsProps) =>
  <SourceView
    paper={paper}
    sourceKey='arxiv'
    getItemsFn={(paper: SourcePaper) => {
      const p = paper as ArxivPaper;
      return [
        getItem(p, 'id'),
        getItem(p, 'title'),
        getItem(p, 'authors', p.authors.join(', ')),
        getItem(p, 'abstract'),
        getItem(p, 'categories', p.categories.join(', ')),
        getItem(p, 'url'),
        getItem(p, 'pdfUrl'),
        getItem(p, 'updated', p.updated.toString()),
        getItem(p, 'published', p.published.toString()),
        getItem(p, 'comment'),
      ];
    }} />;

export const Ar5iv = ({paper}: SourceDetailsProps) =>
  <SourceView
    paper={paper}
    sourceKey='arxiv'
    getItemsFn={(paper: SourcePaper) => {
      const p = paper as Ar5ivPaper;
      return [
        getItem(p, 'textContent', p.textContent?.length.toString()),
      ];
    }} />;

export const SemanticScholar = ({paper}: SourceDetailsProps) =>
  <SourceView
    paper={paper}
    sourceKey='semanticScholar'
    getItemsFn={(paper: SourcePaper) => {
      const p = paper as SemanticScholarPaper;
      return [
        getItem(p, 'paperId'),
        getItem(p, 'title'),
        getItem(p, 'abstract'),
        getItem(
            p,
            'authors',
            p.authors &&
          p.authors.map((a) => `${a.name} (${a.authorId})`).join(', '),
        ),
        getItem(p, 'url'),
        getItem(
            p,
            'externalIds',
            p.externalIds &&
          Object.entries(p.externalIds)
              .map(([key, val]) => `${key}: ${val}`)
              .join(', '),
        ),
        getItem(p, 'fieldsOfStudy', p.fieldsOfStudy?.join(', ')),
        getItem(p, 'citationCount', p.citationCount?.toLocaleString()),
        getItem(p, 'referenceCount', p.referenceCount?.toLocaleString()),
        getItem(p, 'isOpenAccess'),
        getItem(p, 'tldr', p.tldr?.text),
        getItem(p, 'openAccessPdf', p.openAccessPdf?.url),
      ];
    }} />;

export const CrossRef = ({paper}: SourceDetailsProps) =>
  <SourceView
    paper={paper}
    sourceKey='crossRef'
    getItemsFn={(paper: SourcePaper) => {
      const p = paper as CrossRefPaper;
      return [
        getItem(p, 'title'),
        getItem(
            p,
            'authors',
            p.authors.map((a) => `${a.given} ${a.family}`).join(', '),
        ),
        getItem(p, 'url'),
        getItem(p, 'citationCount'),
        getItem(p, 'containerTitle', p.containerTitle.join(', ')),
        getItem(p, 'created'),
        getItem(p, 'deposited'),
        getItem(p, 'indexed'),
        getItem(p, 'publisher'),
      ];
    }} />;

export const PaperShelf = ({paper}: SourceDetailsProps) =>
  <SourceView
    paper={paper}
    sourceKey='paperShelf'
    getItemsFn={(paper: SourcePaper) => {
      return [
        getItem(paper, 'id'),
        getItem(paper, 'title'),
        getItem(paper, 'alias'),
        getItem(paper, 'abstract'),
      ];
    }} />;

export const OpenReview = ({paper}: SourceDetailsProps) =>
  <SourceView
    paper={paper}
    sourceKey='openReview'
    getItemsFn={(paper: SourcePaper) => {
      const p = paper as OpenReviewPaper;
      return [
        getItem(paper, 'id'),
        getItem(paper, 'original'),
        getItem(paper, 'title', p.content.title),
        getItem(paper, 'abstract', p.content.abstract),
        getItem(paper, 'tl;dr', p.content['TL;DR']),
        getItem(paper, 'code', p.content.code),
        getItem(paper, 'bibtex', p.content._bibtex),
        getItem(paper, 'replyto'),
        getItem(paper, 'invitation'),
        getItem(paper, 'keywords', p.content.keywords?.join(', ')),
        getItem(paper, 'pdf', p.content.pdf),
        getItem(paper, 'venue', p.content.venue),
        getItem(paper, 'venueid', p.content.venueid),
      ];
    }} />;
