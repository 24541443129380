import {pick} from 'lodash';

export type TagProps = {
  key: string;
  text?: string;
  description?: string;
  hidden?: boolean;
  icon?: string;
  style?: string;
  priority?: number;
};

/**
 * Tag
 */
type Tag = {
  key: string;
  text?: string;
  description: string;
  hidden: boolean;
  icon?: string;
  style: string;
  priority: number;
}

/**
 * Initialize an empty tag
 * @param props - props
 * @returns Tag type object
 */
export function createNewTag(props: Record<string, unknown>) {
  return {
    key: '',
    description: '',
    hidden: false,
    style: 'primary',
    priority: 0,
    ...props,
  };
}

/**
 * @returns minimal json string for serialization
 */
export function getTagJSON(t: Tag): Record<string, unknown> {
  return pick(t, [
    'key',
    'text',
    'description',
    'hidden',
    'style',
    'icon',
    'priority',
  ]);
}

export const escapeTagKey = (s: string): string =>
  s.toLowerCase().replace(' ', '-');

export const tagCompareFn = (t1: Tag, t2: Tag) => {
  // if (paper?.tags.includes(t1.key) != paper?.tags.includes(t2.key)) {
  //   return paper?.tags.includes(t2.key) ? 1 : -1;
  // }
  if (t2.priority != t1.priority) {
    return t2.priority - t1.priority;
  }
  return t1.key > t2.key ? 1 : -1;
};

export default Tag;
