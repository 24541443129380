import {Badge, Text, useTheme} from '@rneui/themed';
import React from 'react';
import {View} from 'react-native';

import Tag from '../common/tag';
import {getIconByName} from '../platform/icons';

export const TagIcon = ({icon}: {
  icon?: string
}) => {
  const {theme} = useTheme();
  if (!icon) return <></>;
  const iconElement = getIconByName(icon);
  return iconElement ? iconElement({
    size: 12,
    color: theme.colors.realWhite,
  }) : <></>;
};

export const PaperTag = ({tag}: { tag: Tag }): JSX.Element => {
  const {theme} = useTheme();
  return <Badge
    status={
      tag.style as 'success' | 'warning' | 'primary' | 'error' | undefined
    }
    badgeStyle={{borderWidth: 0}}
    value={
      <View testID='tag_content' style={{
        flexDirection: 'row',
        paddingHorizontal: 4,
        alignItems: 'center',
      }}
      >
        <TagIcon icon={tag.icon} />
        {tag.text && <Text style={{
          color: theme.colors.realWhite,
          fontSize: 12,
          marginLeft: tag.icon ? 4 : 0,
        }}>{tag.text}</Text>}
      </View>
    }
  />;
};
