import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import React, {useContext} from 'react';
import {useRecoilValue} from 'recoil';

import Collection, {collectionSortFn} from '../../common/collection';
import {
  ItemKind,
  PreferenceItemProps,
  PreferencesScreen,
} from '../../components/Preferences';
import {AppContext} from '../../context';
import {
  IconAddCollection,
} from '../../platform/icons';
import {allCollectionsState} from '../../recoil/atoms';
import {SettingsStackParamList} from './Navigator';

export const Collections = () => {
  const navigation =
    useNavigation<StackNavigationProp<SettingsStackParamList>>();
  return (
    <CollectionList
      onShowDetails={(c) => {
        navigation.navigate('CollectionDetails', {collectionKey: c.key});
      }}
    />
  );
};

const CollectionList = ({
  onShowDetails,
}: {
  onShowDetails: (c: Collection) => void;
}): JSX.Element => {
  const navigation = useNavigation<
    StackNavigationProp<SettingsStackParamList>>();
  const allCollections = useRecoilValue(allCollectionsState);
  const {newCollection} = useContext(AppContext);

  return (
    <PreferencesScreen
      testID="pref_collections"
      sections={[
        {
          id: 'new',
          items: () => [
            {
              id: 'new_collection',
              kind: ItemKind.Button,
              icon: IconAddCollection,
              title: 'New Collection',
              onPress: async () => {
                onShowDetails(await newCollection('New Collection'));
                // Alert.prompt(
                //     'New Collection',
                //     'Please specify the name of the new collection',
                //     async (text) => {
                //       const c = await actions.createNewCollection(text);
                //       onShowDetails(c);
                //     },
                //     'plain-text',
                // );
              },
            },
          ],
        },
        {
          id: 'collections',
          title: '',
          items: () =>
            [
              ...allCollections.filter((c) => c.starred),
              ...allCollections.filter((c) => !c.starred),
            ].sort(collectionSortFn).map((c) => ({
              id: c.key,
              testID: 'pref_item_collection',
              title: c.name,
              kind: ItemKind.Menu,
              subTitle: c.description,
              onPress: () => navigation.navigate(
                  'CollectionDetails', {collectionKey: c.key}),
              // route: 'PrefCollectionDetails',
              // routeParams: {collection: c.key},
            } as PreferenceItemProps)),
        },
      ]}
    />
  );
};
