import {db} from '../../platform/firebase';

export type NewsItem = {
  key: string;
  content: string;
  createdAt: number;
  date: string;
  header: string;
  url: string;
};

export default {
  find: async (numItems = 6): Promise<NewsItem[]> => {
    const snapshot = await db?.ref('news').limitToLast(numItems).once('value');
    return Object.entries(snapshot?.val()).map(
        ([key, value]) =>
          ({...(value as Record<string, unknown>), key} as NewsItem),
    );
  },
};
