import {
  NavigatorScreenParams,
  useNavigation} from '@react-navigation/native';
import {
  createStackNavigator, StackNavigationProp} from '@react-navigation/stack';
import {Text, useTheme} from '@rneui/themed';
import React, {useContext, useEffect, useState} from 'react';
import {Pressable, View} from 'react-native';
import {FlatList} from 'react-native-gesture-handler';
import {useRecoilValue} from 'recoil';

import Api from '../common/api';
import Collection from '../common/collection';
import ActivityIndicator from '../components/ActivityIndicator';
import Button from '../components/Button';
import {ScreenContainer} from '../components/ScreenContainer';
import {AppContext, NavigationContext} from '../context';
import {IconClose} from '../platform/icons';
import {toastSuccess} from '../platform/toast';
import {
  allCollectionsState,
  windowDimensionState} from '../recoil/atoms';
import {isPhoneState, isWebState, useModalState} from '../recoil/selectors';
import {
  CollectionPreviewNavigator,
  CollectionPreviewStackParamList,
} from './CollectionPreview';

export type PublicCollectionsStackParamList = {
  Main: undefined;
  CollectionPreviewRoot:
    NavigatorScreenParams<CollectionPreviewStackParamList>;
};

const Stack = createStackNavigator<PublicCollectionsStackParamList>();

const PublicCollectionNavigator = (): JSX.Element => {
  const {closeModal} = useContext(NavigationContext);
  const isWeb = useRecoilValue(isWebState);
  const useModal = useRecoilValue(useModalState);
  const {theme} = useTheme();
  return (
    <View style={{flex: 1, height: '100%'}}>
      <Stack.Navigator
        initialRouteName={'Main'}
        screenOptions={{
          headerBackTitle: 'Back',
          animationEnabled: false,
          headerStatusBarHeight: 0,
          headerRight: () => useModal && isWeb ?
        <Pressable
          testID='btn_close'
          onPress={closeModal}
          style={{marginRight: 16}}>
          <IconClose />
        </Pressable> :
        (useModal ? <Button title="Close" onPress={closeModal} /> : null),
          headerStyle: {
            borderBottomColor: theme.colors?.border,
            borderBottomWidth: 1,
            backgroundColor: theme.colors?.white,
          },
        }}
      >
        <Stack.Screen
          key="Main"
          name="Main"
          component={PublicCollections}
          options={{
            headerBackTitle: 'Back',
            title: 'Public Collections',
          }}
        />
        <Stack.Screen
          key="CollectionPreviewRoot"
          name="CollectionPreviewRoot"
          component={CollectionPreviewNavigator}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </View>
  );
};

const PublicCollectionList = ({
  collections,
  onChange,
  title,
}: {
  collections: Collection[],
  onChange?: (c: Collection) => void,
  title: string
}) => {
  const isPhone = useRecoilValue(isPhoneState);
  const isWeb = useRecoilValue(isWebState);
  const windowDimension = useRecoilValue(windowDimensionState);

  const numColumns = () => {
    if (isPhone) return 1;
    if (windowDimension.width < 600) return 1;
    return 3;
  };

  return <View style={{flex: 1}}>
    <Text numberOfLines={1}>{title}</Text>
    <FlatList
      key={numColumns()}
      style={{
        paddingTop: isWeb ? 12 : 0,
        paddingHorizontal: 8,
        marginBottom: 8,
      }}
      data={collections}
      renderItem={({item: c}) => <PublicCollectionCard
        key={c.key} collection={c} onChange={onChange} />}
      numColumns={numColumns()}
      columnWrapperStyle={numColumns() === 1 ? undefined : {
        flex: 1,
        // justifyContent: 'space-around',
      }}
      keyExtractor={(item) => item.key}
      bounces={false}
    />
  </View>;
};

const PublicCollectionCard = ({
  collection,
  onChange,
}: {
  collection: Collection,
  onChange?: (c: Collection) => void,
}) => {
  const {theme} = useTheme();
  const allCollections = useRecoilValue(allCollectionsState);
  const navigation =
    useNavigation<StackNavigationProp<PublicCollectionsStackParamList>>();
  const {savePublicCollection} = useContext(AppContext);

  const isSavedToLocal = () => allCollections.some(
      (_c) => _c.publicCollectionKey === collection.key);

  const view = () => {
    onChange && onChange(collection);
    navigation.push('CollectionPreviewRoot',
        {screen: 'PublicCollectionPreview', params: {collection: collection}});
  };

  return <View style={{
    backgroundColor: theme.colors.white,
    borderRadius: 8,
    marginBottom: 16,
    marginHorizontal: 8,
    flex: 1 / 3,
  }}>
    <Pressable style={{
      paddingHorizontal: 16,
      paddingTop: 16,
      paddingBottom: 8,
    }} onPress={view}>
      <Text numberOfLines={1} style={{
        fontSize: 20,
      }}>{collection.name}</Text>
      <Text style={{marginTop: 4}}>
        {collection.publicInfo?.numPapers} papers
      </Text>
      <Text numberOfLines={2} style={{
        marginTop: 8,
        marginBottom: 4,
        height: 42,
      }}>{collection.description || 'No Description.'}</Text>
      {/* <Text>
        {moment(collection.publicInfo?.updatedAt).calendar()}
      </Text> */}
    </Pressable>
    <View style={{
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTopColor: theme.colors.border,
      borderTopWidth: 1}}>
      <Button type="clear" title="View" onPress={view} containerStyle={{
        flex: 1,
        borderRightColor: theme.colors.border,
        borderRightWidth: 1,
      }} />
      <Button
        type="clear"
        title={isSavedToLocal() ? 'Saved' : 'Save'}
        disabled={isSavedToLocal()}
        onPress={async () => {
          await savePublicCollection(collection);
          toastSuccess('Collection saved.');
        }} containerStyle={{flex: 1}} />
    </View>
  </View>;
};

export const PublicCollections = (
    {onChange}: {onChange?: (c: Collection) => void},
) => {
  const [collections, setCollections] = useState<Collection[]>();
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const loadPublicCollections = async () => {
    setRefreshing(true);
    const cs = await Api.Collection.getPublicCollections(10);
    setCollections(cs);
    setRefreshing(false);
  };

  useEffect(() => {
    loadPublicCollections();
  }, []);

  return <View style={{flex: 1, height: '100%'}}>
    {refreshing ? <View style={{
      height: '100%',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    }}>
      <ActivityIndicator />
    </View> :
    (collections && <PublicCollectionList
      title=""
      onChange={onChange}
      collections={collections} />)}
  </View>;
};

const PublicCollectionsScreen = () => <ScreenContainer edges={['bottom']}>
  <PublicCollectionNavigator />
</ScreenContainer>;

export default PublicCollectionsScreen;
