import {merge} from 'lodash';
import _ from 'lodash';
import {SourceKey, SourcePaper, Sources} from 'paper-fetch';
import Realm, {UpdateMode} from 'realm';

import {isWeb} from '../../platform';
// import Realm from 'realm';
import {db, functions} from '../../platform/firebase';
import {
  localStorageGetItem,
  localStorageRemoveItem,
  localStorageSetItem,
} from '../../platform/localStorage';
import Paper, {
  createNewPaper,
  fetchPaper,
  getPaperId,
  getPaperLocalJSON,
  getPaperRemoteJSON,
  getPaperRemotePublicJSON,
  PartialPaper,
  refreshPaper,
} from '../paper';
import {RealmPaper} from '../realm';
import {uid, updateLastSynced} from './utils';

/**
 * Save a paper to local storage. Before saving, fields are refreshed if
 * necessary. dateModified is updated to the current time unless it is
 * specified.
 * @param paper - a paper
 * @param date - the timestamp to set for dateModified. Empty for now.
 * @returns saved paper
 */
const saveLocal = async (
    paper: Paper, date?: number, realm?: Realm): Promise<Paper> => {
  const p = refreshPaper(paper);
  if (!p.dateAdded) {
    p.dateAdded = date || Date.now();
  }
  p.dateModified = date || Date.now();

  const data = JSON.stringify(getPaperLocalJSON(p));

  // Save to local storage
  if (isWeb) {
    await localStorageSetItem(`paper:${p.id}`, data);
    for (const sourceKey of Object.keys(p.sources) as SourceKey[]) {
      if (!p.sources[sourceKey]) continue;
      await localStorageSetItem(
          `paperSource:${sourceKey}:${p.id}`,
          JSON.stringify(p.sources[sourceKey]));
    }
  } else {
    if (!realm) return paper;
    realm.write(() => {
      realm.create(RealmPaper, {
        id: p.id,
        title: p.title,
        authorFull: p.authorFull,
        abstract: p.abstract,
        alias: p.alias,
        customAlias: p.customAlias,
        venue: p.venue,
        venueShort: p.venueShort,
        year: p.year,
        numCitations: p.numCitations,
        dateAdded: p.dateAdded,
        dateModified: p.dateModified,
        dateOpened: p.dateOpened,
        tags: p.tags.join(';'),
        sources: JSON.stringify(p.sources),
        data: data,
        pdfPath: p.pdfPath,
        availableOffline: p.availableOffline,
      }, UpdateMode?.Modified || 'modified');
    });
  }

  p.size = data.length * 2;
  return p;
};

const loadFromLocalStorage = async (
    pid: string): Promise<Paper | undefined> => {
  const json = await localStorageGetItem(`paper:${pid}`);
  if (!json) return undefined;
  const data = JSON.parse(json);
  const paper = refreshPaper({
    ...createNewPaper(pid),
    ...data,
    size: json.length * 2,
  } as Paper);
  return paper;
};

const loadLocal = async (
    pid: string,
    realm?: Realm): Promise<Paper | undefined> => {
  if (isWeb) return loadFromLocalStorage(pid);
  if (!realm) return undefined;
  const realmPaper = realm.objectForPrimaryKey(RealmPaper, pid);
  if (!realmPaper) return undefined;
  const json = realmPaper.data;
  const data = JSON.parse(json);
  const paper = refreshPaper({
    ...createNewPaper(pid),
    ...data,
    size: json.length * 2,
  } as Paper);
  return paper;
};

const _getPartialPaperFromRealmPaper = (p: RealmPaper) => {
  return {
    id: p.id,
    title: p.title,
    alias: p.alias,
    customAlias: p.customAlias,
    authorFull: p.authorFull,
    venue: p.venue,
    venueShort: p.venueShort,
    numCitations: p.numCitations,
    tags: p.tags?.split(';') || [],
    dateAdded: p.dateAdded,
    dateModified: p.dateModified,
    dateOpened: p.dateOpened,
    year: p.year,
    pdfPath: p.pdfPath,
    availableOffline: p.availableOffline,
  } as PartialPaper;
};

const getPartialPaper = (p: Paper) => {
  return {
    id: p.id,
    title: p.title,
    alias: p.alias,
    customAlias: p.customAlias,
    authorFull: p.authorFull,
    venue: p.venue,
    venueShort: p.venueShort,
    numCitations: p.numCitations,
    tags: p.tags,
    dateAdded: p.dateAdded,
    dateModified: p.dateModified,
    dateOpened: p.dateOpened,
    year: p.year,
    pdfPath: p.pdfPath,
    availableOffline: p.availableOffline,
  } as PartialPaper;
};

const local = {
  save: saveLocal,
  load: loadLocal,
  loadFromLocalStorage,
  loadPartial: async (pid: string, realm?: Realm) => {
    if (!realm) return;
    const realmPaper = realm.objectForPrimaryKey('Paper', pid);
    if (!realmPaper) return undefined;
    return _getPartialPaperFromRealmPaper(realmPaper as RealmPaper);
  },
  query: async (realm?: Realm) => {
    if (!realm) return [];

    const papers = realm.objects('Paper') as Realm.Results<RealmPaper>;
    // return papers.map((p) => refreshPaper({
    //   ...createNewPaper(p.id),
    //   ...JSON.parse(p.data),
    // }));
    return papers.map((p: RealmPaper) => _getPartialPaperFromRealmPaper(p));
  },
  loadSource: async (pid: string, sourceKey: SourceKey) => {
    const data = await localStorageGetItem(`paperSource:${sourceKey}:${pid}`);
    if (!data) return null;
    return JSON.parse(data) as SourcePaper;
  },
  loadSources: async (
      pid: string, realm?: Realm): Promise<Record<string, SourcePaper>> => {
    if (isWeb) {
      const sources = await Promise.all(Sources.map(async (src) => {
        return [src.key, await local.loadSource(pid, src.key)];
      }));
      return Object.fromEntries(sources);
    } else {
      if (!realm) return {};
      const realmPaper = realm.objectForPrimaryKey('Paper', pid);
      if (!realmPaper) return {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return JSON.parse((realmPaper as any).sources as string);
    }
  },
  remove,
};

const server = {
  get: async (pid: string): Promise<Paper | null> => {
    if (!uid()) return null;
    if (!db) return null;
    try {
      const snapshot = await db.ref(
          `users/${uid()}/papers/${pid}`).once('value');
      const data = snapshot.val();
      return data ? {
        ...createNewPaper(pid),
        ...data,
      } as Paper : null;
    } catch (e: unknown) {
      console.log(e);
      return null;
    }
  },
  /**
   * Save paper to the server.
   * @param paper - a paper
   */
  save: async (paper: Paper): Promise<void> => {
    if (!uid()) return;
    if (!paper.id) return;
    await db?.ref(`users/${uid()}/papers/${paper.id}`).set(
        JSON.parse(
            JSON.stringify({
              ...getPaperRemoteJSON(paper),
            }),
        ),
    );
    await db?.ref(`users/${uid()}/dateModified/papers/${paper.id}`)
        .set(paper.dateModified);

    await updateLastSynced();
    console.log('Paper saved to server', paper.id);
  },
  sync: async (realm?: Realm) => {
    if (!uid()) return false;
    let updated = false;
    const syncFn = async () => {
      const dateModified = (await db?.ref(
          `users/${uid()}/dateModified/papers/`).once('value')
          )?.val() as Record<string, number> || {};
      // const localPaperIds = (await localStorageGetKeys()).filter((key) =>
      //   key.startsWith('paper:')).map((key) => key.split(':')[1]);
      const localPaperIds = (await local.query()).map((p) => p.id);
      const allIds = [...(
        new Set([...Object.keys(dateModified), ...localPaperIds]))];
      await Promise.all(allIds.map(async (id) => {
        const localPaper = await local.load(id, realm);
        if (localPaper && !localPaper.dateModified) {
          throw new Error('Invalid dateModified: ' + id);
        }
        if (!localPaper ||
            (localPaper.dateModified || 0) < (dateModified[id] || 0)) {
          updated = true;
          console.log('Downloaded paper: ', id,
              'local:', localPaper?.dateModified,
              'remote:', dateModified[id]);
          const remotePaper = await server.get(id);
          if (!remotePaper) {
            await db?.ref(`users/${uid()}/dateModified/papers/${id}`)
                .remove();
            return;
          }
          const updatedPaper = _.merge(
              localPaper || createNewPaper(id), remotePaper);
          await local.save(updatedPaper, dateModified[id], realm);
        } else if (!dateModified[id] ||
            (localPaper.dateModified || 0) > dateModified[id]) {
          updated = true;
          console.log('Uploaded paper: ' + id);
          await server.save(localPaper);
        }
      }));
    };
    if (isWeb) await syncFn();
    else if (realm) await realm.write(syncFn);
    return updated;
  },
  remove: async (pid: string) => {
    if (!uid()) return;
    await db?.ref(`users/${uid()}/papers/${pid}`).remove();
    await updateLastSynced();
  },
};

/**
 * Send public, non-personal information about a paper to the server.
 * @param paper - a paper
 */
const updatePublicPaperRecord = async (paper: Paper) => {
  await functions?.httpsCallable('updatePaper')(
      getPaperRemotePublicJSON(paper));
};

/**
 * Fetch info from different sources for a paper.
 *
 * @param paper - a paper
 * @param fetchPaperSources - list of source names
 * @param updateProgressFn - a function that will be called when each source is
 * fetched
 * @param onPaperIdChanged - a function that will be called when the paper id is
 * changed (due to new info from sources)
 * @returns the updated paper
 */
async function fetch(
    paper: Paper,
    fetchPaperSources: SourceKey[],
    forceRefresh?: boolean,
    updateProgressFn?: (paper: Paper, msg: string) => void,
): Promise<Paper> {
  const p = await fetchPaper(
      paper, fetchPaperSources, forceRefresh, updateProgressFn);

  // Change paper id if needed
  const newId = await getPaperId(p);
  if (newId && p.id !== newId) {
    p.id = newId;
    p.dateModified = Date.now();
  }

  return p;
}

/**
 * Remove a paper in local storage and server.
 * @param pid - paper id
 */
async function remove(pid: string, realm?: Realm): Promise<void> {
  if (isWeb) {
    await localStorageRemoveItem(`paper:${pid}`);
    for (const sourceKey of Sources.map((s) => s.key)) {
      await localStorageRemoveItem(`paperSource:${sourceKey}:${pid}`);
    }
  } else {
    if (!realm) return;
    realm.write(() => {
      const paper = realm.objectForPrimaryKey('Paper', pid);
      realm.delete(paper);
    });
  }
  await server.remove(pid);
}

const onPaperDeleted = (callback: (pid: string) => void) => {
  if (!db || !uid()) return;
  db?.ref(`users/${uid()}/papers`).off('child_removed');
  db?.ref(`users/${uid()}/papers`).on('child_removed', async (data) => {
    if (data.key) {
      await localStorageRemoveItem(`paper:${data.key}`);
      callback(data.key);
    }
  });
  return () => db?.ref(`users/${uid()}/papers`).off('child_removed');
};

const onPaperChanged = (callback: (pid: string) => void) => {
  if (!db || !uid()) return;
  db?.ref(`users/${uid()}/papers`).off('child_changed');
  db?.ref(`users/${uid()}/papers`).on('child_changed', async (data) => {
    if (!data.key) return;
    const paperJson = await localStorageGetItem(`paper:${data.key}`);
    const d = paperJson ?
        merge(JSON.parse(paperJson), data.val()) :
        data.val();
    await localStorageSetItem(`paper:${data.key}`, JSON.stringify(d));
    callback(data.key);
  });
  return () => db?.ref(`users/${uid()}/papers`).off('child_changed');
};

export default {
  save: saveLocal,
  fetch,
  load: loadLocal,
  remove,
  onPaperDeleted,
  onPaperChanged,
  updatePublicPaperRecord,
  getPartialPaper,
  server,
  local,
};
