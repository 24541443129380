
/**
 * Realm schema for Paper
 */
export class RealmPaper {
  // empty
}

/**
 * Realm schema for Note
 */
export class RealmNote {
  // empty
}
